import Button from "react-bootstrap/Button";
import Pagination from "@mui/material/Pagination";
import Modal from "react-bootstrap/Modal";
import NoDataImg from "../../../assets/img/no-data.gif";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uplodateLoading } from "../../../redux/features/common/commonSlice";
import urls from "../../constants/apiurl";
import ApiCaller from "../../constants/ApiCaller";
import { showDangerToast } from "../../constants/toaster";
import Utils from "../../constants/Utils";
export const SubAdminLogs = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [view, setView] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
  };

  useEffect(() => {
    if (props?.id && props?.show) {
      getSubAdminLog();
    }
  }, [page, props]);

  const getSubAdminLog = async (pageNo = pagerecord.pageNumber) => {
    try {
      dispatch(uplodateLoading(true));
      let postJson = {
        id: props?.id,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
      };
      let path = urls.subAdminLogDetails;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.items);
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10,
        });
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Sub Admin Activities
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {datalist && datalist.length > 0 ? (
          <>
            <div className="table-responsive">
              <table className="table align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Module</th>
                    <th scope="col">Activity Time</th>
                    <th scope="col">Activity Information</th>
                  </tr>
                </thead>
                <tbody>
                  {datalist &&
                    datalist?.map((data, i) => {
                      return (
                          <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{data?.module}</td>
                            <td>{Utils.formatDate(data?.createdAt)}</td>
                            <td>{data?.message}</td>
                          </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        ) : (
          <>
            <>
              <div className="text-center">
                <img width={"25%"} src={NoDataImg}></img>
                <br />
                <label style={{ fontWeight: "bold" }}>No Data Found</label>
              </div>
            </>
          </>
        )}
        {datalist && datalist.length > 0 ? (
          <div className="card-footer py-4">
            <Pagination
              className="custom-pagination-class"
              count={pagerecord?.totalPages}
              page={page}
              onChange={handleChange}
              color="secondary"
            />
          </div>
        ) : (
          <></>
        )}
      </Modal.Body>
    </Modal>
  );
};
