import Switch from '@mui/material/Switch'
import Pagination from '@mui/material/Pagination'
import NoDataImg from '../../../assets/img/no-data.gif'
import { useState } from 'react'
import { showToast } from '../../constants/toaster'
import { useSelector } from 'react-redux'

const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const InfluencerAdsTable = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [showEditBead, setShowEditBead] = useState(false)
  const [userId, setUserId] = useState()
  const [showViewUserDetails, setShowViewUserDetails] = useState(false)
  const handleEditBeads = item => {
    setShowEditBead(true)
  }

  const submitEditBeads = () => {
    setShowEditBead(false)
    showToast('Beads Update Successfully.')
  }

  return (
    <>
      <div className='table-responsive'>
        {props?.datalist?.length ? (
          <>
            <table className='table align-items-center table-flush'>
              <thead className='thead-light'>
                <tr>
                  <th scope='col'>S/No.</th>
                  <th scope='col'>Video Title</th>
                  <th scope='col'>User Name</th>
                  <th scope='col'>Shop Link</th>
                  <th scope='col'>Created At</th>
                  <th scope='col'>Images</th>
                  <th scope='col'>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Testing</td>
                  <td>Jhon@yo</td>
                  <td>
                    <a href='#'>Link</a>
                  </td>
                  <td>Auguest 27, 2023 12:00 PM</td>
                  <td>
                    <div className='row'>
                      <div className='col-md-2'>
                        <img
                          src='https://5.imimg.com/data5/BG/UM/MY-23375112/61.jpg'
                          style={{ width: '25%' }}
                        ></img>
                        <img
                          src='https://5.imimg.com/data5/BG/UM/MY-23375112/61.jpg'
                          style={{ width: '25%' }}
                        ></img>
                        <img
                          src='https://5.imimg.com/data5/BG/UM/MY-23375112/61.jpg'
                          style={{ width: '25%' }}
                        ></img>
                        <img
                          src='https://5.imimg.com/data5/BG/UM/MY-23375112/61.jpg'
                          style={{ width: '25%' }}
                        ></img>
                        <img
                          src='https://5.imimg.com/data5/BG/UM/MY-23375112/61.jpg'
                          style={{ width: '25%' }}
                        ></img>
                      </div>
                    </div>
                  </td>
                  <td>
                    <Switch {...label} defaultChecked size='small' />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <div className='text-center'>
              <img width={'25%'} src={NoDataImg}></img>
              <br />
              <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> :<>No Data Found</>}
                          </label>
            </div>
          </>
        )}
      </div>
      {props?.datalist && props?.datalist.length > 0 ? (
        <div className='card-footer py-4'>
          <Pagination
            className='custom-pagination-class'
            count={props?.pagerecord?.totalPages}
            page={props?.page}
            onChange={props?.handleChange}
            color='secondary'
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
