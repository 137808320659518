import { useState } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
export const AmountTransactionFilter = props => {
  const [type, setTypeDropdownText] = useState('All')

  return (
    <>
      <div className='col-md-1 mb-2 '>
        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #cad1d7',
              boxShadow: 'none',
              transform: 'none'
            }}
            id='dropdown-basic'
          >
            {type}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setTypeDropdownText('All')}>
              All
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setTypeDropdownText('Withdraw Commission')}
            >
              Withdraw Commission
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setTypeDropdownText('Beads Purchase')}
            >
              Beads Purchase
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setTypeDropdownText('UserName Purchase')}
            >
              UserName Purchase
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}
