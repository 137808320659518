
import { useEffect, useState } from "react"
import NoDataImg from "../../../assets/img/no-data.gif";
import Pagination from "@mui/material/Pagination";
import { useDispatch, useSelector } from "react-redux"
import { showDangerToast, showToast } from "../../constants/toaster"
import { uplodateLoading } from "../../../redux/features/common/commonSlice"
import urls from "../../constants/apiurl"
import ApiCaller from "../../constants/ApiCaller"
import Utils from "../../constants/Utils";

export const PaidUserTable = (props) => {

  const [showEditBead, setShowEditBead] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [datalist, setDatalist] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)

  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
  };

  const submitEditBeads = () => {
    setShowEditBead(false)
    showToast('Beads Update Successfully.')
  }

  useEffect(() => {
    getUserList()
  }, [sort_order, sort_type, page])

  useEffect(() => {
    if (props?.paidFilterClick) {
      getUserList(props?.paidSearchText)
    }
  }, [props?.paidFilterClick])

  const getUserList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        paid: true,
        startDate:
          startDateRef && startDateRef !== 'MM/DD/YYYY'
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== 'MM/DD/YYYY' ? endDateRef.toDate() : ''
      }
      let path = urls.userList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }


  return (
    <>
      <div className='table-responsive'>
        {datalist?.length ? (<>
          <table className='table align-items-center table-flush'>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>User ID</th>
                <th scope='col'>User Name</th>
                <th scope='col'>Mobile No.</th>
                <th scope='col'>Registered Date</th>
                <th scope='col'>Name</th>
                <th scope='col'>User Mail ID</th>
                {/* <th scope='col'>Amount Paid</th>
              <th scope='col'>Transaction ID</th> */}
                <th scope='col'>Account Status</th>
                <th scope='col'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {datalist?.map((data, i) => {
                return <>
                  <tr>
                    <td>{data?.id}</td>
                    <td>{data?.userName}</td>
                    <td>+237 {data?.contactNumber}</td>
                    <td>{Utils.formatDate(data?.createdAt)}</td>
                    <td>{data?.fullName}</td>
                    <td>{data?.email}</td>
                    <td>
                      <span className='badge badge-dot mr-4'>
                        <i className='bg-warning' /> Completed
                      </span>
                    </td>
                    <td className='text-right'>
                      <div className='dropdown'>
                        <a
                          className='btn btn-sm btn-icon-only text-light'
                          href='#'
                          role='button'
                          data-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <i className='fas fa-ellipsis-v' />
                        </a>
                        <div
                          className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                          x-placement='bottom-end'
                          style={{
                            position: 'absolute',
                            willChange: 'transform',
                            top: '0px',
                            left: '0px',
                            transform: 'translate3d(32px, 32px, 0px)'
                          }}
                        >
                          <a className='dropdown-item' href='#'>
                            View
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              })}

            </tbody>
          </table>
        </>) : (<>
          <div className="text-center">
            <img width={"25%"} src={NoDataImg}></img>
            <br />
            <label style={{ fontWeight: 'bold' }}>
              {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
            </label>
          </div>
        </>)}
      </div>
      {datalist && datalist.length > 0 ? (
        <div className="card-footer py-4">
          <Pagination
            className="custom-pagination-class"
            count={pagerecord?.totalPages}
            page={page}
            onChange={handleChange}
            color="secondary"
          />
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
