import $ from 'jquery'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCallback, useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import DropBox from '../../custom/DropBox'
import ShowImage from '../../custom/ShowImage'
import { showDangerToast } from '../../constants/toaster'
const imageFileTypes = ['image/png', 'image/gif', 'image/jpeg']
const otherFileTypes = ['image/png', 'image/gif', 'image/jpeg']
// const otherFileTypes = ['.arscene', '.videofx']

export const AddFilter = (props) => {
  const [inputValue, setInputValue] = useState('')

  const handleInputChangeTag = (event) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      props?.setTags([...props?.tags, inputValue.trim()])
      setInputValue('')
    }
  }

  const handleChipDelete = (tagToDelete) => () => {
    props?.setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete))
  }

  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])

  useEffect(() => {
    if (props?.filterFormData?.id) {
      if (props.thumbnailImage) {
        props.setThumbanailControl({
          showError: false,
          errorMessage: '',
          showImage: true,
          imageData: { src: props?.filterFormData?.thumbnailUrl }
        })
      }
    }
  }, [props])

  const clickToRemove = () => {
    props?.setThumbnailImage(null)
  }

  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '')
    props.setFilterFormData({
      ...props.filterFormData,
      duration: newValue
    })
  }

  const handleSubmit = () => {
    if (props?.filterFormData?.id) {
      if (
        props?.filterFormData?.filterName &&
        props?.filterFormData?.duration &&
        props?.filterFormData?.widgetId &&
        props?.filterFormData?.description
      ) {
        props.onSubmit()
      } else {
        showDangerToast('Please fill all required Fields')
      }
    } else {
      if (
        props?.filterFormData?.filterName &&
        props?.filterFormData?.duration &&
        props?.filterFormData?.widgetId &&
        props?.filterFormData?.description &&
        props?.thumbnailImage &&
        props?.filterFile
      ) {
        props.onSubmit()
      } else {
        showDangerToast('Please fill all required Fields')
      }
    }
  }

  console.log('props==========>>>', props)

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader()
        reader.onload = function (e) {
          setImages((prevState) => [
            ...prevState,
            { id: index, src: e.target.result }
          ])
          props?.setThumbnailImage(file)
          props.setThumbanailControl({
            showError: false,
            errorMessage: '',
            showImage: true,
            imageData: { id: index, src: e.target.result }
          })
        }
        reader.readAsDataURL(file)
        return file
      } else {
        props.setThumbanailControl({
          showError: true,
          errorMessage: 'Only Image file is accept. ',
          showImage: false,
          imageData: []
        })
      }
    })
  }, [])

  const onDropFile = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      if (otherFileTypes.includes(file.type)) {
        const reader = new FileReader()
        reader.onload = function (e) {
          setFiles((prevState) => [
            ...prevState,
            { id: index, src: e.target.result }
          ])
          props?.setFilterFile(file)
          props.setFilterFileControl({
            showError: false,
            errorMessage: '',
            showImage: true,
            imageData: { id: index, src: e.target.result }
          })
        }
        reader.readAsDataURL(file)
        return file
      } else {
        props.setFilterFileControl({
          showError: true,
          errorMessage: 'Only .arscene .videofx file is accept.',
          showImage: false,
          imageData: {}
        })
      }
    })
  }, [])
  const [age, setAge] = useState('')

  const handleChange = (event) => {
    setAge(event.target.value)
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.filterFormData?.id ? 'Update Filter' : 'Add Filter'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-8'>
            <Form.Control
              value={props.filterFormData?.filterName}
              onChange={(e) =>
                props.setFilterFormData({
                  ...props.filterFormData,
                  filterName: e.target.value
                })
              }
              placeholder='Enter Filter Name*'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {props?.filterFormData?.filterName ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>
                  Filter Name is required*
                </label>
              </>
            )}
          </div>
          <div className='col-md-4'>
            <Form.Control
              maxLength={4}
              value={props.filterFormData.duration}
              onChange={handleInputChange}
              placeholder='Duration(min)*'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {!props?.filterFormData?.duration ? (
              <label className='custom-error-label'>
                Duration is required*
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-8'>
            <Dropdown
              options={props?.widgetKeyValueData}
              onChange={(e) =>
                props.setFilterFormData({
                  ...props.filterFormData,
                  widgetId: e.value
                })
              }
              value={props?.defaultWidgetOption}
              placeholder='Select Widget*'
            />
            {!props?.filterFormData?.widgetId ? (
              <label className='custom-error-label'>Widget is required*</label>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-12'>
            <TextField
              className='custom-tag-input'
              label='Tags'
              variant='outlined'
              value={inputValue}
              onChange={handleInputChangeTag}
              onKeyDown={handleInputKeyDown}
            />
            <div className='custom-tag-chip-overflow'>
              {props?.tags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  onDelete={handleChipDelete(tag)}
                  style={{ margin: '4px' }}
                />
              ))}
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-12'>
            <Form.Control
              value={props.filterFormData.description}
              onChange={(e) => {
                const inputValue = e.target.value
                if (inputValue.length <= 150) {
                  props.setFilterFormData({
                    ...props.filterFormData,
                    description: inputValue
                  })
                }
              }}
              as='textarea'
              className='mt-3'
              placeholder='Discription*'
            />
            {!props?.filterFormData?.description ? (
              <label className='custom-error-label'>
                Description is required*
              </label>
            ) : (
              <></>
            )}
            {props?.filterFormData?.description &&
            props.filterFormData.description.length > 150 ? (
              <label className='custom-error-label'>
                Description should be 150 characters or less
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            Upload Thumbnail*
            <DropBox
              onDrop={onDrop}
              acceptType='image/*'
              isImage={true}
              thumbanailControl={props.thumbanailControl}
              setThumbanailControl={props.setThumbanailControl}
              clickToRemove={clickToRemove}
            />
            {props.thumbanailControl?.showError ? (
              props.thumbanailControl?.errorMessage
            ) : (
              <></>
            )}
            {props?.filterFormData?.id ? (
              <></>
            ) : props?.thumbnailImage === null ? (
              <label className='custom-error-label'>
                Thumbnail is required*
              </label>
            ) : (
              <></>
            )}
          </div>
          <div className='col-md-6'>
            Upload Filter File*
            <DropBox
              onDrop={onDropFile}
              /*  acceptType='.arscene, .videofx' */
              isImage={false}
              filterFileControl={props.filterFileControl}
            />
            {props.filterFileControl?.showError ? (
              <label className='custom-error-label' style={{ color: 'red' }}>
                {props.filterFileControl?.errorMessage}
              </label>
            ) : (
              <></>
            )}
            {props?.filterFormData?.id ? (
              <></>
            ) : props?.filterFile === null ? (
              <label className='custom-error-label'>
                Filter File is required*
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
