import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import Form from 'react-bootstrap/Form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ReplyIcon from '@mui/icons-material/Reply'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useState } from 'react'

const options = [
  { value: '', label: 'Select User' },
  { value: '1', label: 'Ganesh' }
]
const defaultOption = options[0]

export const AddNotification = props => {
  const [value, setValue] = useState('all')
  const [showUserDropdown, setShowUserDropdown] = useState(false)
  const handleChange = event => {
    setValue(event.target.value)
    if (event.target.value === 'toUser') {
      setShowUserDropdown(true)
    } else {
      setShowUserDropdown(false)
    }
  }

  return (
    <>
      <div className='container-fluid mt--7'>
        <div className='row'>
          <div className='col text-left'>
            <div className='card shadow'>
              <div className='card-header border-0'>
                <div className='row align-items-center '>
                  <div className='col text-left'>
                    <a
                      onClick={() => props.setAddNewNotification(false)}
                      className='btn btn-sm btn-primary text-white'
                    >
                      <ReplyIcon />
                      Cancel
                    </a>
                  </div>
                  <div className='col text-center'>
                    <h3 className='mb-0'>Add Notification</h3>
                  </div>
                  <div className='col text-right'>
                    <a
                      onClick={props.sendNotification}
                      className='btn btn-sm btn-primary text-white'
                    >
                      Send
                    </a>
                  </div>
                </div>
              </div>

              <div className='card-header border-0'>
                <div className='row align-items-center justify-content-center '>
                  <div className='col-md-6'>
                    <Form>
                      <Form.Group
                        className='mb-3'
                        controlId='exampleForm.ControlInput1'
                      >
                        <Form.Label>Notification Title</Form.Label>
                        <Form.Control type='text' placeholder='Title' />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className='row align-items-center justify-content-center '>
                  <div className='col-md-6'>
                    <FormControl>
                      <FormLabel id='demo-row-radio-buttons-group-label'>
                        Users
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby='demo-row-radio-buttons-group-label'
                        name='row-radio-buttons-group'
                        value={value}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value='all'
                          control={<Radio />}
                          label='All'
                        />
                        <FormControlLabel
                          value='toUser'
                          control={<Radio />}
                          label='Any Particular User'
                        />
                        <FormControlLabel
                          value='allSubAdmins'
                          control={<Radio />}
                          label='All Sub Admins'
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                {showUserDropdown ? (
                  <>
                    <div className='row align-items-center justify-content-center '>
                      <div className='col-md-6'>
                        <Dropdown
                          options={options}
                          // onChange={() => alert()}
                          value={defaultOption}
                          placeholder='Select User'
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className='row align-items-center justify-content-center mt-3'>
                  <div className='col-md-6'>
                    <CKEditor
                      editor={ClassicEditor}
                      data='<p>Hello from CKEditor&nbsp;5!</p>'
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor)
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        console.log({ event, editor, data })
                      }}
                      onBlur={(event, editor) => {
                        console.log('Blur.', editor)
                      }}
                      onFocus={(event, editor) => {
                        console.log('Focus.', editor)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
