import $ from 'jquery'
import { useEffect } from 'react'
import NavBar from '../structure/NavBar'
import { useDispatch } from 'react-redux'
import { NavBarTop } from '../structure/NavBarTop'
import { Footer } from '../structure/Footer'
import { RenderHeader } from '../structure/Header'
import {
  updateTopHeader,
  uplodateLoading
} from '../../redux/features/common/commonSlice'
export const Dashboard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    $('body').removeClass('bg-default')
    dispatch(updateTopHeader('Dashboard'))
    dispatch(uplodateLoading(false))
  })

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <RenderHeader />
        
      </div>
    </>
  )
}
