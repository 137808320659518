import Checkbox from '@mui/material/Checkbox'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
export const BeadsTransactionTable = () => {
  return (
    <div className='table-responsive'>
      <table className='table align-items-center table-flush'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>S/No.</th>
            <th scope='col'>Full Name</th>
            <th scope='col'>User Name</th>
            <th scope='col'>User ID</th>
            <th scope='col'>Transaction Type</th>
            <th scope='col'>Transaction ID</th>
            <th scope='col'>Badge</th>
            <th scope='col'>Total Beads</th>
            <th scope='col'>Transaction Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Ganesh Prajapat</td>
            <td>GSK</td>
            <td>1004</td>
            <td>Badge Purchase</td>
            <td>231</td>
            <td>
              {' '}
              <Checkbox {...label} defaultChecked />
            </td>
            <td>120</td>
            <td>12/12/2023</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
