import NoDataImg from "../../../assets/img/no-data.gif";
import Pagination from "@mui/material/Pagination";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "@mui/material/Button";
import dayjs, { Dayjs } from "dayjs";
import NavBar from "../../structure/NavBar";
import Form from "react-bootstrap/Form";
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@mui/icons-material/Add";
import { NavBarTop } from "../../structure/NavBarTop";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useReducer, useState } from "react";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import Image from "../../custom/Image";
import {
    updateTopHeader,
    uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import Utils from '../../constants/Utils'
import ViewQuery from "./ViewQuery";
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const ContactUsList = props => {
    const LOADING_ENABLE = useSelector(state => state?.loading)
    const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
    const [addNewQuestion, setAddNewQuestion] = useState(false)
    const [value, setValue] = useState(dayjs())
    const [searchText, setSearchText] = useState('')
    const [startDate, setStartDate] = useState('MM/DD/YYYY')
    const [endDate, setEndDate] = useState('MM/DD/YYYY')
    const dispatch = useDispatch()
    const [datalist, setDatalist] = useState([])
    const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
    const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
    const [showAddFilter, setShowAddFilter] = useState(false)
    const [sort_type, setSortType] = useState('createdAt')
    const [sort_order, setSortOrder] = useState(false)
    const userData = useSelector(state => state?.userData)
    const [edit, setEdit] = useState(false)
    const [active, setActive] = useState(false)
    const [deletePermission, setDeletePermission] = useState(false)
    const [view, setView] = useState(false)
    const [pagerecord, setPagerecord] = useState({
        totalItems: 0,
        pageNumber: 1,
        pageSize: 10,
        totalPages: 1,
    });
    const [showViewFaq, setShowViewFaq] = useState(false);
    const [contactUsMessage, setContactUsMessage] = useState("")

    const [page, setPage] = useState(1);
    const handleChange = (event, value) => {
        setPage(value);
        setPagerecord({
            totalItems: pagerecord?.totalItems,
            totalPages: pagerecord?.totalPages,
            pageNumber: value,
            pageSize: 10,
        });
    };

    useEffect(() => {
        dispatch(updateTopHeader("Contact Us Manager"));
    }, []);

    useEffect(() => {
        getFaqList();
    }, [sort_order, sort_type, statusDropdownText, page, props]);

    const getFaqList = async (
        searchTextRef,
        startDateRef,
        endDateRef,
        pageNo = pagerecord.pageNumber
    ) => {
        try {
            dispatch(uplodateLoading(true));
            let postJson = {
                email: searchText,
                page: pageNo - 1 || 0,
                limit: pagerecord.pageSize || 10,
                sort: sort_type,
                order: sort_order,
                active:
                    statusDropdownText === "Active"
                        ? true
                        : statusDropdownText === "In Active"
                            ? false
                            : null,
                startDate:
                    startDateRef && startDateRef !== "MM/DD/YYYY"
                        ? startDateRef.toDate()
                        : "",
                endDate:
                    endDateRef && endDateRef !== "MM/DD/YYYY" ? endDateRef.toDate() : "",
            };
            let path = urls.contactUsList;
            let res = await ApiCaller.post(postJson, path, userData?.access_token);
            const response = await res.response.json();
            if (response.status == 200) {
                dispatch(uplodateLoading(false));
                setDatalist(response?.data?.items);
                // response?.data?.permissionList.forEach((module, moduleIndex) => {
                //     if (module.permissionName === "EDIT") {
                //         setEdit(module.isEnable);
                //     } else if (module.permissionName === "ACTIVE") {
                //         setActive(module.isEnable);
                //     } else if (module.permissionName === "DELETE") {
                //         setDeletePermission(module.isEnable);
                //     } else if (module.permissionName === "VIEW") {
                //         setView(module.isEnable);
                //     }
                // });
                setPagerecord({
                    totalItems: response?.data?.totalItems,
                    totalPages: response?.data?.totalPages,
                    pageNumber: pageNo,
                    pageSize: 10,
                });
            } else {
                dispatch(uplodateLoading(false));
                showDangerToast(response.message);
            }
        } catch (error) {
            dispatch(uplodateLoading(false));
            console.error(error);
            showDangerToast(error.message);
        }
    };

    const handleFilterClick = () => {
        getFaqList(searchText, startDate, endDate);
    };

    const handleResetFilter = () => {
        setStartDate("MM/DD/YYYY");
        setEndDate("MM/DD/YYYY");
        setStatusDropdownText("Select Status");
        setSearchText("");
        getFaqList("", "MM/DD/YYYY", "MM/DD/YYYY");
    };



    return (
        <>
            <>
                <NavBar />
                <div className="main-content">
                    <NavBarTop />
                    <div className="header">
                        <div className="logo">
                            <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
                        </div>
                    </div>
                    {addNewQuestion ? (
                        <></>
                    ) : (
                        <>
                            <div className="container-fluid mt--7">
                                <div className="row">
                                    <div className="col text-left">
                                        <div className="card shadow">
                                            <div className="card-header border-0">
                                                <div className="row align-items-center text-left">
                                                    <div className="col">
                                                        <h3 className="mb-0">Contact Us Manager</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-header border-0">
                                                <div className="row align-items-center text-left">
                                                    <div className="col-md-3">
                                                        <InputGroup
                                                            className="mb-2"
                                                            style={{ borderRadius: "10px" }}
                                                        >
                                                            <Form.Control
                                                                placeholder="Search by Email"
                                                                value={searchText}
                                                                onChange={(e) => setSearchText(e.target.value)}
                                                                aria-label="Amount (to the nearest dollar)"
                                                            />
                                                            <InputGroup.Text
                                                                style={{
                                                                    borderTopRightRadius: "10px",
                                                                    borderBottomRightRadius: "10px",
                                                                    borderTopLeftRadius: "0px",
                                                                    borderBottomLeftRadius: "0px",
                                                                }}
                                                            >
                                                                <SearchIcon />
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    </div>
                                                    {/* <div className="col-md-2">
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="success"
                                                                style={{
                                                                    backgroundColor: "white",
                                                                    color: "black",
                                                                    border: "1px solid #cad1d7",
                                                                    boxShadow: "none",
                                                                    transform: "none",
                                                                }}
                                                                id="dropdown-basic"
                                                            >
                                                                {statusDropdownText}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    onClick={() =>

                                                                        setStatusDropdownText("Select Status")
                                                                    }
                                                                >
                                                                    Select Status
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setStatusDropdownText("Active")
                                                                    }
                                                                >
                                                                    Active
                                                                </Dropdown.Item>
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setStatusDropdownText("In Active")
                                                                    }
                                                                >
                                                                    In Active
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div> */}
                                                    <div className="col-md-4 mb-3">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer
                                                                components={["DatePicker", "DatePicker"]}
                                                            >
                                                                <DatePicker
                                                                    disableFuture
                                                                    className="custom-date"
                                                                    label="From Date"
                                                                    value={startDate}
                                                                    onChange={(newValue) =>
                                                                        setStartDate(newValue)
                                                                    }
                                                                />
                                                                <DatePicker
                                                                    disableFuture
                                                                    label="To Date"
                                                                    value={endDate}
                                                                    onChange={(newValue) => setEndDate(newValue)}
                                                                    minDate={startDate}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className="col-md-3 mb-2">
                                                        <Button
                                                            variant="contained"
                                                            className="custom-filter-btn"
                                                            onClick={handleFilterClick}
                                                        >
                                                            Filter
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            className="ml-2 custom-filter-btn"
                                                            onClick={handleResetFilter}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                {datalist?.length > 0 ? (
                                                    <>
                                                        <table className="table align-items-center table-flush">
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    <th scope="col">S/No.</th>
                                                                    <th scope="col">Email</th>
                                                                    <th scope="col">Full Name</th>
                                                                    <th scope="col">Message</th>
                                                                    <th scope="col">Contact On</th>
                                                                    <th scope="col" />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {datalist?.map((data, i) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{i + 1}</td>
                                                                                <td
                                                                                    className="custom-td-width"
                                                                                >{data?.email}</td>
                                                                                <td
                                                                                    className="custom-td-width"
                                                                                >{data?.fullName}</td>
                                                                                <td className="custom-td-width" style={{cursor:'pointer'}} title="Click to view more" onClick={(e) => {
                                                                                    setShowViewFaq(!showViewFaq)
                                                                                    setContactUsMessage(data?.message)
                                                                                }}>{data?.message}</td>
                                                                                <td>
                                                                                    {Utils.formatDate(data?.createdAt)}
                                                                                </td>
                                                                                <td className="text-right">
                                                                                    {/* <div className="dropdown">
                                                                                        <a
                                                                                            className="btn btn-sm btn-icon-only text-light"
                                                                                            href="#"
                                                                                            role="button"
                                                                                            data-toggle="dropdown"
                                                                                            aria-haspopup="true"
                                                                                            aria-expanded="false"
                                                                                        >
                                                                                            <i className="fas fa-ellipsis-v" />
                                                                                        </a>
                                                                                        <div
                                                                                            className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                                                                            x-placement="bottom-end"
                                                                                            style={{
                                                                                                position: "absolute",
                                                                                                willChange: "transform",
                                                                                                top: "0px",
                                                                                                left: "0px",
                                                                                                transform:
                                                                                                    "translate3d(32px, 32px, 0px)",
                                                                                            }}
                                                                                        >
                                                                                            {userData.admin ? (
                                                                                                <a
                                                                                                    className="dropdown-item cursor"
                                                                                                    onClick={() =>
                                                                                                        handleEditFaq(data)
                                                                                                    }
                                                                                                >
                                                                                                    Edit
                                                                                                </a>
                                                                                            ) : edit ? (
                                                                                                <a
                                                                                                    className="dropdown-item cursor"
                                                                                                    onClick={() =>
                                                                                                        handleEditFaq(data)
                                                                                                    }
                                                                                                >
                                                                                                    Edit
                                                                                                </a>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}

                                                                                            <a className="dropdown-item cursor"
                                                                                                onClick={() =>
                                                                                                    handleFaqView(data.id)
                                                                                                }
                                                                                            >
                                                                                                View
                                                                                            </a>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="text-center">
                                                            <img width={"25%"} src={NoDataImg}></img>
                                                            <br />
                                                            <label style={{ fontWeight: 'bold' }}>
                                                                {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                                                            </label>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {datalist.length ? (
                                                <>
                                                    <div className="card-footer py-4">
                                                        <Pagination
                                                            className="custom-pagination-class"
                                                            count={pagerecord?.totalPages}
                                                            page={page}
                                                            onChange={handleChange}
                                                            color="secondary"
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <Footer /> */}
                            </div>
                        </>
                    )}

                    <ViewQuery
                        show={showViewFaq}
                        message={contactUsMessage}
                        onHide={() => setShowViewFaq(false)}
                    />
                </div>
            </>
        </>
    );
};
