import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
export const ViewBeadsDetails = props => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='row'>
          <h3 className='col-md-12 text-center'>View Beads Purchase Details</h3>
        </div>
        <hr />
        <div className='row mt-5'>
          <div className='col-md-6'>
            <b>UserName:</b> Peter
          </div>
          <div className='col-md-6'>
            <b>No. of Beads:</b> 20
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Mobile No:</b> Peter
          </div>
          <div className='col-md-6'>
            <b>Price:</b> 12 NGN
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Email:</b> yen@gmail.com
          </div>
          <div className='col-md-6'>
            <b>Description:</b> Test Description
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <b>Full Name:</b> Peter Jhon
          </div>
          <div className='col-md-6'>
            <b>USER ID:</b> 123
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
        Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
