import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCallback, useEffect, useState } from 'react'
import 'react-dropdown/style.css'

export const ViewUser = props => {
  const [beadsAmount, setBeadsAmount] = useState()
  useEffect(() => {}, [props?.show === true])

  const handleSubmit = () => {
    props?.onSubmit()
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          User Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-2'>
            <img
              style={{ width: '50%', borderRadius: '10px' }}
              src='https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg'
            ></img>
          </div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>User Id :</b> 100
              </div>
              <div className='col-md-12'>
                <b>UserName :</b> Tashi
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>Name :</b> Ganesh
              </div>
              <div className='col-md-12'>
                <b>Mobile :</b> +91 9588034109
              </div>
            </div>
          </div>
          <div className='col-md-2'></div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>Registration :</b> 12-11-2023
              </div>
              <div className='col-md-12'>
                <b>Total Video :</b> 10
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>Total Likes :</b> 10
              </div>
              <div className='col-md-12'>
                <b>Total Subscribers :</b> 10
              </div>
            </div>
          </div>
          <div className='col-md-2'></div>
          <div className='col-md-5 '>
            <div className='row'>
              <div className='col-md-12'>
                <b>Country :</b> Africa
              </div>
              <div className='col-md-12'>
                <b>Gender :</b> Male
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>DOB :</b> 12-11-1998
              </div>
              <div className='col-md-12'>
                <b>Badge Verification :</b> Blue
              </div>
            </div>
          </div>
          <div className='col-md-2'></div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>Age :</b> 28
              </div>
              <div className='col-md-12'>
                <b>Subscription :</b> Gold Package
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>KYC Verification :</b> Verified
              </div>
              <div className='col-md-12'>
                <b>KYC Document :</b> <a href='#'>View Document</a>
              </div>
            </div>
          </div>
          <div className='col-md-2'></div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>Register Via:</b> Mobile
              </div>
              <div className='col-md-12'>
                <b>Total Beads Available :</b> 100
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='row'>
              <div className='col-md-12'>
                <b>Total Tip Beads:</b> 28
              </div>
              <div className='col-md-12'>
                <b>Interests :</b> Sport,Music
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
