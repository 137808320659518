import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const WithdrawList = props => {
  const [statusDropdownText, setStatusDropdownText] = useState('All')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddFilter, setShowAddFilter] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [filterFormData, setFilterFormData] = useReducer(
    (filterFormData, newItem) => {
      return { ...filterFormData, ...newItem }
    },
    {
      id: '',
      title: '',
      duration: '',
      widgetId: '',
      description: '',
      thumbnailUrl: '',
      filterUrl: ''
    }
  )
  const handleOnSubmitFilterModel = () => {}
  const handleAddFilter = () => {
    setFilterFormData({})
    getWidgetKeyValuePair()
    setShowAddFilter(true)
  }

  const getWidgetKeyValuePair = async roleId => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.key === roleId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditFilter = item => {
    alert()
  }

  useEffect(() => {
    dispatch(updateTopHeader('Withdraw Manager'))
  }, [])

  useEffect(() => {
    getFilterList()
  }, [sort_order, sort_type, props])

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeFilterStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getFilterList = async (pageNo = pagerecord.pageNumber) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: '',
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order
      }
      let path = urls.filterList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.items)
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Withdraw Requests</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Keyword'
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className='col-md-4 mb-3'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={value}
                              onChange={newValue => setValue(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={value}
                              onChange={newValue => setValue(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('All')}
                            >
                              All
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Approved')}
                            >
                              Approved
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Declined')}
                            >
                              Declined
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Pending')}
                            >
                              Pending
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table className='table align-items-center table-flush'>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>S/No.</th>
                          <th scope='col'>User Name</th>
                          <th scope='col'>Email</th>
                          <th scope='col'>Mobile Number</th>
                          <th scope='col'>Requested Amount</th>
                          <th scope='col'>Status</th>
                          <th scope='col'>Created At</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>CLuch-GSK</td>
                          <td>ganeshmrr1@gmail.com</td>
                          <td>+91 9588034109</td>
                          <td>100 NGN</td>
                          <td>
                            <span className='badge badge-dot mr-4'>
                              <i className='bg-warning' /> pending
                            </span>
                          </td>
                          <td>12/12/2023</td>
                          <td className='text-right'>
                            <div className='dropdown'>
                              <a
                                className='btn btn-sm btn-icon-only text-light'
                                href='#'
                                role='button'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <i className='fas fa-ellipsis-v' />
                              </a>
                              <div
                                className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                x-placement='bottom-end'
                                style={{
                                  position: 'absolute',
                                  willChange: 'transform',
                                  top: '0px',
                                  left: '0px',
                                  transform: 'translate3d(32px, 32px, 0px)'
                                }}
                              >
                                <a
                                  className='dropdown-item cursor'
                                  onClick={() =>
                                    handleEditFilter({
                                      title: 'Role 1',
                                      description: 'Test Description 1',
                                      id: 1
                                    })
                                  }
                                >
                                  Decline
                                </a>
                                <a
                                  className='dropdown-item cursor'
                                  onClick={() =>
                                    handleEditFilter({
                                      title: 'Role 1',
                                      description: 'Test Description 1',
                                      id: 1
                                    })
                                  }
                                >
                                  Approve
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='card-footer py-4'>
                    <nav aria-label='...'>
                      <ul className='pagination justify-content-end mb-0'>
                        <li className='page-item disabled'>
                          <a className='page-link' href='#' tabIndex={-1}>
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                          </a>
                        </li>
                        <li className='page-item active'>
                          <a className='page-link' href='#'>
                            1
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            2 <span className='sr-only'>(current)</span>
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            3
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </>
    </>
  )
}
