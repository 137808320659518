import React from "react";
import { Button, Modal } from "react-bootstrap";
import Utils from "../../constants/Utils";

const ViewQuery = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-dark">Contact Us Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>{props.message}</>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewQuery;
