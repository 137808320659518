import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import { useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch } from 'react-redux'
import Button from '@mui/material/Button'

export const PaidUserFilters = (props) => {
  const [statusDropdownText, setStatusDropdownText] = useState(
    'Select Account Status'
  )
  const [kycStatusDropdownText, setKycStatusDropdownText] =
    useState('Select Kyc Status')
  const [subscriptionNameDropdownText, setSubscriptionNameDropdownText] =
    useState('Select Subscription')
  const dispatch = useDispatch()
  const [value, setValue] = useState(dayjs())

  return (
    <div className='card-header border-0'>
      <div className='row align-items-center text-left'>
        <div className='col-md-3'>
          <InputGroup className='mb-2' style={{ borderRadius: '10px' }}>
            <Form.Control
              placeholder='Search by Keyword'
              aria-label='Amount (to the nearest dollar)'
              value={props?.paidSearchText}
              onChange={(e) => props?.setPaidSearchText(e.target.value)}
            />
            <InputGroup.Text
              style={{
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px'
              }}
            >
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </div>
        <div className='col-md-1 mb-2 '>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid #cad1d7',
                boxShadow: 'none',
                transform: 'none'
              }}
              id='dropdown-basic'
            >
              {statusDropdownText}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setStatusDropdownText('Select Account Status')}
              >
                Select Account Status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setStatusDropdownText('Completed')}>
                Completed
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setStatusDropdownText('Pending')}>
                Pending
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='col-md-4 mb-3' style={{ marginLeft: '100px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                disableFuture
                className='custom-date'
                label='From Date'
                value={value}
                onChange={newValue => setValue(newValue)}
              />
              <DatePicker
                disableFuture
                label='To Date'
                value={value}
                onChange={newValue => setValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className='col-md-3 mb-2'>
          <Button
            variant='contained'
            className='custom-filter-btn'
            onClick={() => props?.handleFilterClick()}
          >
            Filter
          </Button>
          {/* <Button
            variant='contained'
            className='ml-2 custom-filter-btn'
            onClick={() => props?.handleResetFilter()}
          >
            Reset
          </Button> */}
        </div>
      </div>
    </div>
  )
}
