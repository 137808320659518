import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCallback, useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import DropBox from '../../custom/DropBox'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

import ShowImage from '../../custom/ShowImage'
import { showDangerToast } from '../../constants/toaster'
const imageFileTypes = ['image/png', 'image/gif', 'image/jpeg']
export const AddWidget = props => {
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])


  useEffect(() => {
    if (props?.widgetFormData?.id === '') {
      props.setThumbanailControl({
        showError: false,
        errorMessage: '',
        showImage: false,
        imageData: null
      })
    } else {
      if (props?.thumbnailFile) {
        props.setThumbanailControl({
          showError: false,
          errorMessage: '',
          showImage: true,
          imageData: { src: props?.widgetFormData?.thumbnail }
        })
      }
    }
  }, [props?.show === true])

  const clickToRemove = () => {
    props?.setThumbnailFile(null)
  }

  const handleSubmit = () => {
    console.log('props.thumbanailControl?.src');
    if (props?.widgetFormData?.id) {
      if (
        props?.widgetFormData?.widgetName &&
        props?.widgetFormData?.position &&
        props?.widgetFormData?.widgetType &&
        props?.widgetFormData?.widgetContentType
      ) {
        props.onSubmit()
      } else {
        showDangerToast('Please fill all required Fields')
      }
    } else {
      if (
        props?.widgetFormData?.widgetName &&
        props?.widgetFormData?.position &&
        props?.widgetFormData?.widgetType &&
        props?.widgetFormData?.widgetContentType &&
        props?.thumbnailFile
      ) {
        props.onSubmit()
      } else {
        showDangerToast('Please fill all required Fields')
      }
    }
  }

  const onDrop = useCallback(acceptedFiles => {
    console.log('acceptedFiles', acceptedFiles);
    acceptedFiles.map((file, index) => {
      if (imageFileTypes.includes(file.type)) {
        const reader = new FileReader()
        reader.onload = function (e) {
          setImages(prevState => [
            ...prevState,
            { id: index, src: e.target.result }
          ])
          props?.setThumbnailFile(file)
          props.setThumbanailControl({
            showError: false,
            errorMessage: '',
            showImage: true,
            imageData: { id: index, src: e.target.result }
          })
        }
        reader.readAsDataURL(file)

        return file
      } else {
        props.setThumbanailControl({
          showError: true,
          errorMessage: 'Only Image file is accept. ',
          showImage: false,
          imageData: null
        })
      }
    })
  }, [])

  const handleInputChange = e => {
    const newValue = e.target.value.replace(/[^0-9]/g, '')

    props.setWidgetFormData({
      ...props?.widgetFormData,
      position: newValue
    })
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.widgetFormData?.id ? 'Update Widget' : 'Add Widget'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Control
              value={props.widgetFormData?.widgetName}
              onChange={e =>
                props.setWidgetFormData({
                  ...props?.widgetFormData,
                  widgetName: e.target.value
                })
              }
              placeholder='Widget Name'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {props?.widgetFormData?.widgetName ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>
                  Widget Name is required*
                </label>
              </>
            )}
          </div>
          <div className='col-md-6'>
            <Form.Control
              maxLength={4}
              value={props.widgetFormData?.position}
              onChange={handleInputChange}
              placeholder='Position'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {props?.widgetFormData?.position ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>
                  Position is required*
                </label>
              </>
            )}
          </div>
          <div className='col-md-6 mt-3'>
            <Dropdown
              options={props?.defaultWidgetOptions}
              onChange={e =>
                props.setWidgetFormData({
                  ...props.widgetFormData,
                  widgetType: e.value
                })
              }
              // onChange={() => alert()}
              value={props?.defaultWidgetType}
              placeholder='Select Widget Type'
            />
            {props?.widgetFormData?.widgetType ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>Type is required*</label>
              </>
            )}
          </div>
          <div className='col-md-6 mt-3'>
            <Dropdown
              options={props?.defaultWidgetContentTypeOptions}
              onChange={e =>
                props.setWidgetFormData({
                  ...props.widgetFormData,
                  widgetContentType: e.value
                })
              }
              value={props?.defaultWidgetContentType}
              placeholder='Select Widget Content Type'
            />
            {props?.widgetFormData?.widgetContentType ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>
                  Content Type is required*
                </label>
              </>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            Upload Thumbnail*
            <DropBox
              onDrop={onDrop}
              acceptType='image/*'
              isImage={true}
              thumbanailControl={props.thumbanailControl}
              setThumbanailControl={props.setThumbanailControl}
              clickToRemove={clickToRemove}
            />
            {props.thumbanailControl?.showError ? (
              props.thumbanailControl?.errorMessage
            ) : (
              <></>
            )}
            {props?.widgetFormData?.id ? (
              <></>
            ) : props?.thumbnailFile ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>
                  Thumbnail is required*
                </label>
              </>
            )}

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
         Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
