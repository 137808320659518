import Switch from '@mui/material/Switch'
import Pagination from '@mui/material/Pagination'
import NoDataImg from '../../../assets/img/no-data.gif'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { AddBanner } from './AddBanner'
import Utils from '../../constants/Utils'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const BannerList = props => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [bannerImage, setBannerImage] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState('MM/DD/YYYY')
  const [endDate, setEndDate] = useState('MM/DD/YYYY')
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddBanner, setShowAddBanner] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [bannerFormData, setBannerFormData] = useState({
    id: '',
    name: '',
    hyperLink: '',
    startDate: new Date(),
    endDate: null
  })
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [filterFileControl, setFilterFileControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  const handleOnSubmitBannerModel = async () => {
    try {
      let postJson = {
        name: bannerFormData?.name,
        hyperLink: bannerFormData?.hyperLink,
        startDate: bannerFormData?.startDate,
        endDate: bannerFormData?.endDate,
        id: bannerFormData?.id
      }
      console.log(postJson)
      let form_data = new FormData()
      form_data.append(
        'bannerRequest',
        new Blob([JSON.stringify(postJson)], {
          type: 'application/json'
        })
      )
      form_data.append('image', bannerImage)
      dispatch(uplodateLoading(true))
      let path = urls.addBanner
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        setShowAddBanner(false)
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getBannerList()
        handleHideModel()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleAddFilter = () => {
    setBannerFormData({})
    getWidgetKeyValuePair()
    setBannerFormData(
      {
        id: '',
        name: '',
        imageUrl: '',
        description: ''
      }
    )
    setShowAddBanner(true)
  }

  const handleFilterClick = () => {
    getBannerList(searchText, startDate, endDate)
  }

  const handleResetFilter = () => {
    setStartDate('MM/DD/YYYY')
    setEndDate('MM/DD/YYYY')
    setStatusDropdownText('Select Status')
    setSearchText('')
    getBannerList('')
  }

  const getWidgetKeyValuePair = async roleId => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.key === roleId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditBanner = item => {

    setBannerFormData({
      ...item,
      startDate: new Date(item?.startDate),
      endDate: new Date(item?.endDate)
    })
    if(item?.imageUrl){
      setBannerImage(item?.imageUrl)
    }
    setShowAddBanner(true)
  }

  useEffect(() => {
    dispatch(updateTopHeader('Banner Manager'))
  }, [])

  useEffect(() => {
    getBannerList("", startDate, endDate)
  }, [sort_order, sort_type, props])

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeBannerStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getBannerList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    // This code will execute whenever startDate changes.
    getBannerList('', startDate, endDate, statusDropdownText)
  }, [startDate, endDate, statusDropdownText])

  const getBannerList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
            ? false
            : null,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== 'MM/DD/YYYY'
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== 'MM/DD/YYYY' ? endDateRef.toDate() : ''
      }
      let path = urls.bannerList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleHideModel=()=>{
    setShowAddBanner(false)
    setFilterFileControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Banners List</h3>
                      </div>
                      <div className='col text-right'>
                        {userData.admin ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add New
                          </a>
                        ) : (
                          <></>
                        )}

                        {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Title'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setStatusDropdownText('Select Status')
                              }
                            >
                              Select Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Active')}
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('In Active')}
                            >
                              In Active
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '40px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker                             
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                            />
                            <DatePicker
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S/No.</th>
                              <th scope='col'>Title</th>
                              <th scope='col'>Banner</th>
                              <th scope='col'>Date Of Creation</th>
                              <th scope='col'>End Date</th>
                              <th scope='col'>Status</th>
                              <th scope='col' />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{data?.name}</td>
                                      <td>
                                        <Image
                                          image={{
                                            id: 1,
                                            src: data?.imageUrl
                                          }}
                                          width='80px'
                                          height='auto
'
                                        />
                                      </td>
                                      <td>
                                        {Utils.formatDate(data?.startDate)}
                                      </td>
                                      <td>{Utils.formatDate(data?.endDate)}</td>
                                      <td>
                                        {userData.admin ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={e =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : active ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={e =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td className='text-right'>
                                        <div className='dropdown'>
                                          <a
                                            className='btn btn-sm btn-icon-only text-light'
                                            href='#'
                                            role='button'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                          >
                                            <i className='fas fa-ellipsis-v' />
                                          </a>
                                          <div
                                            className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                            x-placement='bottom-end'
                                            style={{
                                              position: 'absolute',
                                              willChange: 'transform',
                                              top: '0px',
                                              left: '0px',
                                              transform:
                                                'translate3d(32px, 32px, 0px)'
                                            }}
                                          >
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleEditBanner(data)
                                              }
                                            >
                                              Edit
                                            </a>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> :<>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <>
                      <div className='card-footer py-4'>
                        <Pagination
                          className='custom-pagination-class'
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color='secondary'
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
          <AddBanner
            show={showAddBanner}
            bannerFormData={bannerFormData}
            widgetKeyValueData={widgetKeyValueData}
            defaultWidgetOption={defaultWidgetOption}
            setBannerFormData={setBannerFormData}
            setBannerImage={setBannerImage}
            bannerImage={bannerImage}
            onSubmit={() => handleOnSubmitBannerModel()}
            onHide={() => handleHideModel()}
            filterFileControl={filterFileControl}
            setFilterFileControl={setFilterFileControl}
            thumbanailControl={thumbanailControl}
            setThumbanailControl={setThumbanailControl}
          />
        </div>
      </>
    </>
  )
}
