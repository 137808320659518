import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
export const AbuseReportsList = props => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Abuse/Report Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table align-items-center table-flush'>
            <thead className='thead-light'>
              <tr>
                <th scope='col'>S/No.</th>
                <th scope='col'>User Name</th>
                <th scope='col'>User ID</th>
                <th scope='col'>Date</th>
                <th scope='col'>Reason For Report</th>
                <th scope='col'>User Profile(User Name)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Jhon Doe</td>
                <td>1005</td>
                <td>06/10/2023</td>
                <td>Spam</td>
                <td>pinkie</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Jhon Doe</td>
                <td>1005</td>
                <td>06/10/2023</td>
                <td>Spam</td>
                <td>pinkie</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Jhon Doe</td>
                <td>1005</td>
                <td>06/10/2023</td>
                <td>Spam</td>
                <td>pinkie</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Jhon Doe</td>
                <td>1005</td>
                <td>06/10/2023</td>
                <td>Spam</td>
                <td>pinkie</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Jhon Doe</td>
                <td>1005</td>
                <td>06/10/2023</td>
                <td>Spam</td>
                <td>pinkie</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  )
}
