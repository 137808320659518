import Switch from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";
import NoDataImg from "../../../assets/img/no-data.gif";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "@mui/material/Button";
import dayjs, { Dayjs } from "dayjs";
import NavBar from "../../structure/NavBar";
import Form from "react-bootstrap/Form";
import SearchIcon from "@mui/icons-material/Search";
import InputGroup from "react-bootstrap/InputGroup";
import AddIcon from "@mui/icons-material/Add";
import { NavBarTop } from "../../structure/NavBarTop";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useReducer, useState } from "react";
import Image from "../../custom/Image";
import AWS from 'aws-sdk';
import {
  updateTopHeader,
  uplodateLoading,
} from "../../../redux/features/common/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import urls from "../../constants/apiurl";
import ApiCaller from "../../constants/ApiCaller";
import { showDangerToast, showToast } from "../../constants/toaster";
import { AddBlog } from "./AddBlog";
import Utils from "../../constants/Utils";
import Swal from "sweetalert2";
const label = { inputProps: { "aria-label": "Size switch demo" } };
export const BlogList = (props) => {
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [statusDropdownText, setStatusDropdownText] = useState("Select Status");
  const [value, setValue] = useState(dayjs());
  const dispatch = useDispatch();
  const [datalist, setDatalist] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("MM/DD/YYYY");
  const [endDate, setEndDate] = useState("MM/DD/YYYY");
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null);
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([]);
  const [showAddBlog, setShowAddBlog] = useState(false);
  const [sort_type, setSortType] = useState("createdAt");
  const [sort_order, setSortOrder] = useState(false);
  const userData = useSelector((state) => state?.userData);
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1,
  });
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [edit, setEdit] = useState(false);
  const [active, setActive] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [view, setView] = useState(false);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10,
    });
  };



  const uploadFile = async () => {
    // S3 Bucket Name
    const S3_BUCKET = "circo-dev-cloud-front";

    // S3 Region
    const REGION = "us-east-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIA6G46TGIERBDPQTP5",
      secretAccessKey: "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",
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    // Files Parameters
    const fileName = "blogs/" + thumbnailFile?.name;
    console.log(fileName)
    const params = {
      Bucket: S3_BUCKET,
      Key: fileName,
      Body: thumbnailFile,
    };

    // Uploading file to s3

    var upload = await s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();
    // await upload.then((err, data) => {
    //   alert("File upload successfully.")
    //   console.log("Image URL: " + "https://dzv0fidwp2q1d.cloudfront.net/" + fileName)
    // });
    return "https://dzv0fidwp2q1d.cloudfront.net/" + fileName;
  };

  const [blogFormData, setBlogFormData] = useState({
    id: "",
    name: "",
    description: "",
    imageUrl: "",
    startDate: new Date(),
    endDate: null,
  });

  const handleFilterClick = () => {
    getBlogList(searchText, startDate, endDate);
  };

  const handleResetFilter = () => {
    setStartDate("MM/DD/YYYY");
    setEndDate("MM/DD/YYYY");
    setStatusDropdownText("Select Status");
    setSearchText("");
    getBlogList("");
  };

  const handleOnSubmitFilterModel = async () => {
    dispatch(uplodateLoading(true));
    let tempImageUrl;
    let { id,
      name,
      description,
      imageUrl,
      startDate,
      endDate } = { ...blogFormData }
    if (id) {
      if (thumbnailFile !== null && thumbnailFile !== undefined && thumbnailFile !== imageUrl) {
        const responseImage = await uploadFile();
        try {
          imageUrl = responseImage
          let path = urls.addBlog;
          var resBlog = await ApiCaller.post(
            {
              id,
              name,
              description,
              imageUrl,
              startDate,
              endDate
            },
            path,
            userData?.access_token
          );
          const responseBlog = await resBlog.response.json();
          if (responseBlog.status == 200) {
            setShowAddBlog(false);
            dispatch(uplodateLoading(false));
            showToast(responseBlog?.message);
            getBlogList();
          } else {
            dispatch(uplodateLoading(false));
            showDangerToast(responseBlog.message);
          }
        } catch (error) {
          dispatch(uplodateLoading(false));
          console.error(error);
          showDangerToast(error.message);
        }
      } else {
        try {
          let path = urls.addBlog;
          var resBlog = await ApiCaller.post(
            {
              id,
              name,
              description,
              imageUrl,
              startDate,
              endDate
            },
            path,
            userData?.access_token
          );
          const responseBlog = await resBlog.response.json();
          if (responseBlog.status == 200) {
            setShowAddBlog(false);
            dispatch(uplodateLoading(false));
            showToast(responseBlog?.message);
            getBlogList();
          } else {
            dispatch(uplodateLoading(false));
            showDangerToast(responseBlog.message);
          }
        } catch (error) {
          dispatch(uplodateLoading(false));
          console.error(error);
          showDangerToast(error.message);
        }
      }
    } else {
      if (thumbnailFile === null || thumbnailFile === undefined) {
        showDangerToast("Image is requried.")
      } else {
        try {
          const response = await uploadFile();
          if (response) {
            try {
              imageUrl = response
              let path = urls.addBlog;
              var resBlog = await ApiCaller.post(
                {
                  id,
                  name,
                  description,
                  imageUrl,
                  startDate,
                  endDate
                },
                path,
                userData?.access_token
              );
              const responseBlog = await resBlog.response.json();
              if (responseBlog.status == 200) {
                setShowAddBlog(false);
                dispatch(uplodateLoading(false));
                showToast(responseBlog?.message);
                getBlogList();
              } else {
                dispatch(uplodateLoading(false));
                showDangerToast(responseBlog.message);
              }
            } catch (error) {
              dispatch(uplodateLoading(false));
              console.error(error);
              showDangerToast(error.message);
            }
          } else {
            alert("Something went wrong while upload image.")
            dispatch(uplodateLoading(false));
            showDangerToast(response.message);
          }
        } catch (error) {
          dispatch(uplodateLoading(false));
          console.error(error);
          showDangerToast(error.message);
        }
      }
    }
  };
  const handleAddFilter = () => {
    setBlogFormData({
      id: "",
      name: "",
      description: "",
      startDate: new Date(),
      endDate: null,
    });
    getWidgetKeyValuePair();
    setShowAddBlog(true);
  };

  const getWidgetKeyValuePair = async (roleId) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.widgetKeyValuePair;
      let res = await ApiCaller.get({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        let tempWidgetKeyValueList = [{ value: "", label: "Select Widget" }];
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key });
          if (data?.key === roleId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key });
          }
        });
        setWidgetKeyValueData(tempWidgetKeyValueList);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleDelteBlog = async (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteBlog + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getBlogList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditBlog = (item) => {
    console.log('item=============>>>>>>>>>>', item);
    if (item?.imageUrl) {
      setThumbnailFile(item?.imageUrl)
    }
    setBlogFormData({
      ...item,
      startDate: new Date(item?.startDate),
      endDate: item?.endDate ? new Date(item?.endDate) : null,
    });
    setThumbanailControl({
      showError: false,
      errorMessage: '',
      showImage: false,
      imageData: { id: "", src: item?.imageUrl }
    })
    setShowAddBlog(true);
  };

  useEffect(() => {
    dispatch(updateTopHeader("Blog Manager"));
  }, []);

  useEffect(() => {
    getBlogList();
  }, [sort_order, sort_type, page, props]);

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true));
      let path = urls.changeBlogStatus + "/" + id;
      let res = await ApiCaller.put({}, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        showToast(response?.message);
        getBlogList("", startDate, endDate, statusDropdownText);
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  useEffect(() => {
    // This code will execute whenever startDate changes.
    getBlogList("", startDate, endDate, statusDropdownText);
  }, [startDate, endDate, statusDropdownText]);

  const getBlogList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true));
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        active:
          statusDropdownText === "Active"
            ? true
            : statusDropdownText === "In Active"
              ? false
              : null,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== "MM/DD/YYYY"
            ? startDateRef.toDate()
            : "",
        endDate:
          endDateRef && endDateRef !== "MM/DD/YYYY" ? endDateRef.toDate() : "",
      };
      let path = urls.blogList;
      let res = await ApiCaller.post(postJson, path, userData?.access_token);
      const response = await res.response.json();
      if (response.status == 200) {
        dispatch(uplodateLoading(false));
        setDatalist(response?.data?.response?.items);
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === "EDIT") {
            setEdit(module.isEnable);
          } else if (module.permissionName === "ACTIVE") {
            setActive(module.isEnable);
          } else if (module.permissionName === "DELETE") {
            setDeletePermission(module.isEnable);
          } else if (module.permissionName === "VIEW") {
            setView(module.isEnable);
          }
        });
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10,
        });
      } else {
        dispatch(uplodateLoading(false));
        showDangerToast(response.message);
      }
    } catch (error) {
      dispatch(uplodateLoading(false));
      console.error(error);
      showDangerToast(error.message);
    }
  };

  const handleHideBlogModel = () => {
    setShowAddBlog(false)
    setThumbanailControl({
      showError: false,
      errorMessage: '',
      showImage: true,
      imageData: null
    })

  }

  return (
    <>
      <>
        <NavBar />
        <div className="main-content">
          <NavBarTop />
          <div className="header">
            <div className="logo">
              <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8"></div>
            </div>
          </div>
          <div className="container-fluid mt--7">
            <div className="row">
              <div className="col text-left">
                <div className="card shadow">
                  <div className="card-header border-0">
                    <div className="row align-items-center text-left">
                      <div className="col">
                        <h3 className="mb-0">Blogs List</h3>
                      </div>
                      <div className="col text-right">
                        {userData.admin ? (
                          <a
                            onClick={handleAddFilter}
                            className="btn btn-sm btn-primary text-white"
                          >
                            <AddIcon />
                            Add Blog
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddFilter}
                            className="btn btn-sm btn-primary text-white"
                          >
                            <AddIcon />
                            Add Blog
                          </a>
                        ) : (
                          <></>
                        )}

                        {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="card-header border-0">
                    <div className="row align-items-center text-left">
                      <div className="col-md-3">
                        <InputGroup
                          className="mb-2"
                          style={{ borderRadius: "10px" }}
                        >
                          <Form.Control
                            placeholder="Search by Blog Name"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            aria-label="Amount (to the nearest dollar)"
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="col-md-1 mb-2 ">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              border: "1px solid #cad1d7",
                              boxShadow: "none",
                              transform: "none",
                            }}
                            id="dropdown-basic"
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setStatusDropdownText("Select Status")
                              }
                            >
                              Select Status
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText("Active")}
                            >
                              Active
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText("In Active")}
                            >
                              In Active
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div
                        className="col-md-4 mb-3"
                        style={{ marginLeft: "40px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                          >
                            <DatePicker
                              disableFuture
                              className="custom-date"
                              label="From Date"
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label="To Date"
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className="col-md-3 mb-2">
                        <Button
                          variant="contained"
                          className="custom-filter-btn"
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant="contained"
                          className="ml-2 custom-filter-btn"
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {datalist?.length ? (
                      <table className="table align-items-center table-flush">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">S/No.</th>
                            <th scope="col">Blog Name</th>
                            <th scope="col">Image</th>
                            <th scope="col">Date Of Creation</th>
                            <th scope="col">Status</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {datalist?.map((data, i) => {
                            return (
                              <>
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{data?.name}</td>
                                  <td>
                                    <Image
                                      image={{
                                        id: 1,
                                        src: data?.imageUrl,
                                      }}
                                      width="80px"
                                      height="auto
"
                                    />
                                  </td>
                                  <td>{Utils.formatDate(data?.createdAt)}</td>
                                  <td>
                                    {userData.admin ? (
                                      <Switch
                                        {...label}
                                        checked={data?.active}
                                        onChange={(e) =>
                                          handleStatusChange(e, data?.id)
                                        }
                                        size="small"
                                      />
                                    ) : active ? (
                                      <Switch
                                        {...label}
                                        checked={data?.active}
                                        onChange={(e) =>
                                          handleStatusChange(e, data?.id)
                                        }
                                        size="small"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </td>
                                  <td className="text-right">
                                    <div className="dropdown">
                                      <a
                                        className="btn btn-sm btn-icon-only text-light"
                                        href="#"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </a>
                                      <div
                                        className="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                                        x-placement="bottom-end"
                                        style={{
                                          position: "absolute",
                                          willChange: "transform",
                                          top: "0px",
                                          left: "0px",
                                          transform:
                                            "translate3d(32px, 32px, 0px)",
                                        }}
                                      >
                                        {userData.admin ? (
                                          <>
                                            <a
                                              className="dropdown-item cursor"
                                              onClick={() => handleEditBlog(data)}
                                            >
                                              Edit
                                            </a>
                                            <a
                                              className="dropdown-item cursor"
                                              onClick={() => handleDelteBlog(data)}
                                            >
                                              Delete
                                            </a>
                                          </>
                                        ) : edit ? (
                                          <a
                                            className="dropdown-item cursor"
                                            onClick={() => handleEditBlog(data)}
                                          >
                                            Edit
                                          </a>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <div className="text-center">
                          <img width={"25%"} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist.length ? (
                    <>
                      <div className="card-footer py-4">
                        <Pagination
                          className="custom-pagination-class"
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color="secondary"
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
          <AddBlog
            show={showAddBlog}
            blogFormData={blogFormData}
            widgetKeyValueData={widgetKeyValueData}
            defaultWidgetOption={defaultWidgetOption}
            setBlogFormData={setBlogFormData}
            onSubmit={() => handleOnSubmitFilterModel()}
            onHide={() => handleHideBlogModel()}
            thumbnailFile={thumbnailFile}
            setThumbnailFile={setThumbnailFile}
            thumbanailControl={thumbanailControl}
            setThumbanailControl={setThumbanailControl}
          />
        </div>
      </>
    </>
  );
};
