import { useState } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'
export const BeadsTransactionFilter = props => {
  const [type, setTypeDropdownText] = useState('All')
  const [badgeType, setBadgeTypeDropdownText] = useState('All')

  return (
    <>
      <div className='col-md-1 mb-2 mr-5 '>
        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #cad1d7',
              boxShadow: 'none',
              transform: 'none'
            }}
            id='dropdown-basic'
          >
            {type}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setTypeDropdownText('All')}>
              All
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setTypeDropdownText('Join Live Streaming')}
            >
              Join Live Streaming
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setTypeDropdownText('Tips')}>
              Tips
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setTypeDropdownText('Badge Purchase')}
            >
              Badge Purchase
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => setTypeDropdownText('Post Boost')}>
              Post Boost
            </Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='col-md-1 mb-2 ml-5'>
        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid #cad1d7',
              boxShadow: 'none',
              transform: 'none'
            }}
            id='dropdown-basic'
          >
            {badgeType}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setBadgeTypeDropdownText('All')}>
              All
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setBadgeTypeDropdownText('Badge')}>
              Badge
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  )
}
