import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch } from 'react-redux'
import { updateTopHeader } from '../../../redux/features/common/commonSlice'
import { ViewBeadsDetails } from './ViewBeadsDetails'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const BeadsList = () => {
  const [showViewBeadsDetails, setShowViewBeadsDetails] = useState(false)
  const [statusDropdownText, setStatusDropdownText] = useState('Profile')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [showAddFontStyle, setShowAddFontStyle] = useState(false)
  const [fontStyleFormData, setFontStyleFormData] = useReducer(
    (fontStyleFormData, newItem) => {
      return { ...fontStyleFormData, ...newItem }
    },
    {
      id: '',
      assetName: '',
      type: '',
      description: '',
      coverImageUrl: '',
      remotePackageUrl: ''
    }
  )
  const handleOnSubmitFilterModel = () => {}
  const handleAddFilter = () => {
    setFontStyleFormData({})
    setShowAddFontStyle(true)
  }
  const handleEditFilter = item => {
    alert()
  }

  useEffect(() => {
    dispatch(updateTopHeader('Beads Purchase Manager'))
  })

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Beads Purchase Manager</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by User ID or User Name'
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={value}
                              onChange={newValue => setValue(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={value}
                              onChange={newValue => setValue(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table className='table align-items-center table-flush'>
                      <thead className='thead-light'>
                        <tr>
                          <th scope='col'>S/No.</th>
                          <th scope='col'>User ID</th>
                          <th scope='col'>User Name</th>
                          <th scope='col'>Beads Count</th>
                          <th scope='col'>Taken Time</th>
                          <th scope='col'>Price</th>
                          <th scope='col' />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>1001</td>
                          <td>Cluch--SK</td>
                          <td>100</td>
                          <td>12/12/2023</td>
                          <td>500 NGN</td>
                          <td className='text-right'>
                            <div className='dropdown'>
                              <a
                                className='btn btn-sm btn-icon-only text-light'
                                href='#'
                                role='button'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                              >
                                <i className='fas fa-ellipsis-v' />
                              </a>
                              <div
                                className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                x-placement='bottom-end'
                                style={{
                                  position: 'absolute',
                                  willChange: 'transform',
                                  top: '0px',
                                  left: '0px',
                                  transform: 'translate3d(32px, 32px, 0px)'
                                }}
                              >
                                <a
                                  className='dropdown-item cursor'
                                  onClick={() => setShowViewBeadsDetails(true)}
                                >
                                  View Details
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='card-footer py-4'>
                    <nav aria-label='...'>
                      <ul className='pagination justify-content-end mb-0'>
                        <li className='page-item disabled'>
                          <a className='page-link' href='#' tabIndex={-1}>
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                          </a>
                        </li>
                        <li className='page-item active'>
                          <a className='page-link' href='#'>
                            1
                          </a>
                        </li>
                        {/* <li className='page-item'>
                          <a className='page-link' href='#'>
                            2 <span className='sr-only'>(current)</span>
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            3
                          </a>
                        </li> */}
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewBeadsDetails
          show={showViewBeadsDetails}
          onHide={() => setShowViewBeadsDetails(false)}
        />
      </>
    </>
  )
}
