var urls = {
  adminLogin: '/api/admin-service/auth/login',
  profileDetail: '/api/admin-service/auth/get-profile-details',
  updateProfile: '/api/admin-service/update-profile',
  updatePassword: '/api/admin-service/update-password',
  addRole: '/api/admin-service/role/add-role',
  roleList: '/api/admin-service/role/role-list',
  changeRoleStatus: '/api/admin-service/role/change/status',
  rolePermissionDetails: '/api/admin-service/role-permission-details',
  roleKeyValuePair: '/api/admin-service/role/role-key-value',
  rolePermissionUpdate: '/api/admin-service/set-permission',
  fetchPermission: '/api/admin-service/role-permission-details',

  subAdminByRole: '/api/admin-service/subadmin/key-value-by-role',
  subAdminList: '/api/admin-service/subadmin/get/all/paged',
  subAdminAdd: '/api/admin-service/subadmin/create',
  subAdminChangeStatus: '/api/admin-service/subadmin/status',
  subAdminLogDetails: '/api/admin-service/subadmin/log-details',
  subAdminDelete: '/api/admin-service/subadmin/delete/by/id/',

  addFilter: '/api/admin-service/filter/add-filter',
  filterList: '/api/admin-service/filter/filter-list',
  changeFilterStatus: '/api/admin-service/filter/change/status',
  deleteFilter: '/api/admin-service/filter/delete/by/id',
  addNotification: '/api/admin-service/add/notification',
  notificationList: '/api/admin-service/notification-list',

  addFilter: '/api/admin-service/filter/add-filter',
  filterList: '/api/admin-service/filter/filter-list',
  changeFilterStatus: '/api/admin-service/filter/change/status',

  addEffect: '/api/admin-service/effects/add',
  effectList: '/api/admin-service/effects/effect-list',
  changeEffectStatus: '/api/admin-service/effects/change/status',
  getEffect: '/api/admin-service/effects/get/by',
  deleteEffect: '/api/admin-service/effects/delete/by/id',

  addFontStyle: '/api/admin-service/font-style/add',
  fontStyleList: '/api/admin-service/font-style/list',
  changeFontStyleStatus: '/api/admin-service/font-style/change/status',
  getFontStyle: '/api/admin-service/font-style/get/by',
  deleteFontStyle: '/api/admin-service/font-style/delete/by',

  addSound: '/api/admin-service/sounds/add',
  soundList: '/api/admin-service/sounds/sound-list',
  changeSoundStatus: '/api/admin-service/sounds/change/status',
  getSound: '/api/admin-service/sounds/get/by',
  deleteSound: '/api/admin-service/sounds/delete/by/id',

  reportAbuseList: '/api/admin-service/report-abuse/list',

  userList: '/api/admin-service/users/user-list',

  addWidget: '/api/admin-service/widget/add-widget',
  widgetList: '/api/admin-service/widget/widget-list',
  widgetKeyValuePair: '/api/admin-service/widget/key-value',
  changeWidgetStatus: '/api/admin-service/widget/change/status',
  getWidget: '/api/admin-service/widget/get/by',
  deleteWidget: '/api/admin-service/widget/delete/by/id',

  countryList: '/api/admin-service/country/country-list',
  addCountry: '/api/admin-service/country/add',
  changeCountryStatus: '/api/admin-service/country/change/status',

  addTagInterest: '/api/admin-service/tags/add',
  tagInterestList: '/api/admin-service/tags/tag-list',
  changeTagInterestStatus: '/api/admin-service/tags/change/status',

  addBanner: '/api/admin-service/banners/add',
  bannerList: '/api/admin-service/banners/banner-list',
  changeBannerStatus: '/api/admin-service/banners/change/status',

  addSticker: '/api/admin-service/stickers/add',
  stickerList: '/api/admin-service/stickers/list',
  changeStickerStatus: '/api/admin-service/stickers/change/status',
  deleteSticker: '/api/admin-service/stickers/delete/by/id',

  addFaq: '/api/admin-service/faqs/add',
  faqList: '/api/admin-service/faqs/faq-list',
  changeFaqStatus: '/api/admin-service/faqs/change/status',
  updateSequence: '/api/admin-service/faqs/update-seq',
  faqById: '/api/admin-service/faqs/get/by',

  contactUsList: '/api/admin-service/contact-us/list',

  uploadImage:'/api/admin-service/blogs/upload-image',
  addBlog: '/api/admin-service/blogs/add',
  blogList: '/api/admin-service/blogs/blog-list',
  changeBlogStatus: '/api/admin-service/blogs/change/status',
  deleteBlog: '/api/admin-service/blogs/delete/by/id',

  addAds: '/api/admin-service/ads/add',
  adsList: '/api/admin-service/ads/ad-list',
  changeAdsStatus: '/api/admin-service/ads/change/status',
  deleteAds: '/api/admin-service/ads/delete/by/id',

  updateStaticContent: '/api/admin-service/contents/add',
  staticContentList: '/api/admin-service/contents/static-list',

  setGlobalSetting: '/api/admin-service/settings/add',
  getGlobalSetting: '/api/admin-service/settings/get-details',

  updateEmail: '/api/admin-service/email-template/add',
  emailList: '/api/admin-service/email-template/template-list',
  testAddUser: '/api/user-service/users/signup-complete'
}
export default urls
