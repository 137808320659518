import { useEffect, useReducer, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import NoDataImg from '../../../assets/img/no-data.gif'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import InputGroup from 'react-bootstrap/InputGroup'
import Pagination from '@mui/material/Pagination'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import AddIcon from '@mui/icons-material/Add'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import Switch from '@mui/material/Switch'
import { AddCountry } from './AddCountry'
import { data } from 'jquery'
import Utils from '../../constants/Utils'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const CountryList = (props) => {
  const LOADING_ENABLE = useSelector((state) => state?.loading)
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [value, setValue] = useState(dayjs())
  const [startDate, setStartDate] = useState('MM/DD/YYYY')
  const [endDate, setEndDate] = useState('MM/DD/YYYY')
  const userData = useSelector((state) => state?.userData)
  const [searchText, setSearchText] = useState('')
  const [showAddCountry, setShowAddCountry] = useState(false)
  const [datalist, setDatalist] = useState([])
  const [viewPermissionControl, setViewPermissionControl] = useState(false)
  const [viewSubAdminList, setViewSubAdminList] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [countryFormData, setCountryFormData] = useState({
    id: '',
    name: '',
    code: '',
    mobileLength: ''
  })
  const [permissionSubmitData, setPermissionSubmitData] = useState([])
  const [rolePermissionData, setRolePermissionData] = useState({})
  const [subAdminListByRole, setSubAdminListByRole] = useState([])
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  const handleFilterClick = () => {
    getCountryList(searchText, startDate, endDate)
  }
  const handleResetFilter = () => {
    setStartDate('MM/DD/YYYY')
    setEndDate('MM/DD/YYYY')
    setStatusDropdownText('Select Status')
    setSearchText('')
    getCountryList('', 'MM/DD/YYYY', 'MM/DD/YYYY')
  }
  useEffect(() => {
    dispatch(updateTopHeader('Country Manager'))
  }, [])
  useEffect(() => {
    getCountryList('', startDate, endDate)
  }, [sort_order, sort_type, page, props])

  useEffect(() => {
    // This code will execute whenever startDate changes.
    getCountryList('', startDate, endDate)
  }, [startDate, endDate, statusDropdownText])

  const getGetRolePermssions = async (id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.rolePermissionDetails + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setRolePermissionData(response?.data)
        setViewPermissionControl(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const getSubAdminsByRoleId = async (id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.subAdminByRole + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setSubAdminListByRole(response?.data)
        setViewSubAdminList(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeCountryStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getCountryList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const getCountryList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
            ? false
            : null,
        startDate:
          startDateRef && startDateRef !== 'MM/DD/YYYY'
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== 'MM/DD/YYYY' ? endDateRef.toDate() : ''
      }
      let path = urls.countryList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditCountry = (item) => {
    setCountryFormData(item)
    setShowAddCountry(true)
  }

  const handleAddCountry = () => {
    setCountryFormData({
      id: '',
      name: '',
      code: ''
    })
    setShowAddCountry(true)
  }

  const handleOnSubmitCountryModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.addCountry
      let res = await ApiCaller.post(
        countryFormData,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        setShowAddCountry(false)
        getCountryList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const submitPermissionControl = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postData = {
        permissions: permissionSubmitData
      }
      let path = urls.rolePermissionUpdate
      let res = await ApiCaller.post(postData, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        setViewPermissionControl(false)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Country List</h3>
                    </div>
                    <div className='col text-right'>
                      {userData.admin ? (
                        <a
                          onClick={handleAddCountry}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : edit ? (
                        <a
                          onClick={handleAddCountry}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add New
                        </a>
                      ) : (
                        <></>
                      )}

                      {/* <a href='#!' className='btn btn-sm btn-primary'>
                        See all
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-3'>
                      <InputGroup
                        className='mb-2'
                        style={{ borderRadius: '10px' }}
                      >
                        <Form.Control
                          placeholder='Search by Country Name'
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          aria-label='Amount (to the nearest dollar)'
                        />
                        <InputGroup.Text
                          style={{
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px'
                          }}
                        >
                          <SearchIcon />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className='col-md-2'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {statusDropdownText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setStatusDropdownText('Select Status')
                            }
                          >
                            Select Status
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Active')}
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('In Active')}
                          >
                            In Active
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className='col-md-4 mb-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3 mb-2'>
                      <Button
                        variant='contained'
                        className='custom-filter-btn'
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='table-responsive'>
                  {datalist && datalist?.length > 0 ? (
                    <>
                      <table className='table align-items-center table-flush'>
                        <thead className='thead-light'>
                          <tr>
                            <th scope='col'>S/No.</th>
                            <th scope='col'>Country Code</th>
                            <th scope='col'>Country</th>
                            <th scope='col'>Created At</th>
                            <th scope='col'>Status</th>
                            <th scope='col' />
                          </tr>
                        </thead>
                        <tbody>
                          {datalist &&
                            datalist?.map((data, i) => {
                              return (
                                <>
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{data?.code}</td>
                                    <td>{data?.name}</td>
                                    <td>{Utils.formatDate(data?.createdAt)}</td>
                                    <td>
                                      {userData.admin ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          onChange={(e) =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : active ? (
                                        <Switch
                                          {...label}
                                          checked={data?.active}
                                          onChange={(e) =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className='text-right'>
                                      <div className='dropdown'>
                                        <a
                                          className='btn btn-sm btn-icon-only text-light'
                                          href='#'
                                          role='button'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <i className='fas fa-ellipsis-v' />
                                        </a>
                                        <div
                                          className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                          x-placement='bottom-end'
                                          style={{
                                            position: 'absolute',
                                            willChange: 'transform',
                                            top: '0px',
                                            left: '0px',
                                            transform:
                                              'translate3d(32px, 32px, 0px)'
                                          }}
                                        >
                                          {userData.admin ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleEditCountry(data)
                                              }
                                            >
                                              Edit
                                            </a>
                                          ) : active ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleEditCountry(data)
                                              }
                                            >
                                              Edit
                                            </a>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <>
                      <div className='text-center'>
                        <img width={'25%'} src={NoDataImg}></img>
                        <br />
                        <label style={{ fontWeight: 'bold' }}>
                          {LOADING_ENABLE ? (
                            <>Loading Data.....</>
                          ) : (
                            <>No Data Found</>
                          )}
                        </label>
                      </div>
                    </>
                  )}
                </div>
                {datalist && datalist.length > 0 ? (
                  <div className='card-footer py-4'>
                    <Pagination
                      className='custom-pagination-class'
                      count={pagerecord?.totalPages}
                      page={page}
                      onChange={handleChange}
                      color='secondary'
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddCountry
          show={showAddCountry}
          countryFormData={countryFormData}
          setCountryFormData={setCountryFormData}
          onSubmit={() => handleOnSubmitCountryModel()}
          onHide={() => setShowAddCountry(false)}
        />
      </div>
    </>
  )
}
