import 'react-dropdown/style.css'
import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Utils from '../../constants/Utils'
export const ViewFontStyle = props => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='modal-header-custom' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.fontStyleViewFormData?.type === 'FONT' ? 'Font ' : 'Style '}{' '}
          Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-6'>
            <div className='row mt-1'>
              <div className='col-md-6'>
                <div className='row ml-1'>
                  <h4>Name:</h4>
                  <h4 className='ml-1'>{props.fontStyleViewFormData.name}</h4>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row ml-1'>
                  <h4>Active:</h4>
                  {props.fontStyleViewFormData.active ? (
                    <h4 className='ml-1'>Yes</h4>
                  ) : (
                    <h4 className='ml-1'>No</h4>
                  )}
                </div>
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-md-6'>
                <div className='row ml-1'>
                  <h4>Description:</h4>
                  <h4 className='ml-1'>
                    {props.fontStyleViewFormData.description}
                  </h4>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row ml-1'>
                  <h4>Created At:</h4>
                  <h4 className='ml-1'>
                    {Utils.formatDate(props?.fontStyleViewFormData?.createdAt)}
                  </h4>
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-md-12'>
                <div className='row ml-1'>
                  <h4>Remote Package:</h4>
                  <h4 className='ml-1'>
                    <a
                      href={props.fontStyleViewFormData.remotePackage}
                      target='_blank'
                      rel='noreferrer'
                    >
                      View Effect
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='row mt-1'>
              <div className='col-md-6'>
                <div className='row ml-1'>
                  <h4>Type:</h4>
                  <h4 className='ml-1'>
                    {props.fontStyleViewFormData.type?.charAt(0).toUpperCase() +
                      props.fontStyleViewFormData.type?.slice(1).toLowerCase()}
                  </h4>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row ml-1'>
                  <h4>Tags:</h4>
                  <h4>
                    {props.fontStyleViewFormData.tags &&
                    props.fontStyleViewFormData.tags.length
                      ? props.fontStyleViewFormData.tags?.join(', ')
                      : '-'}{' '}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-2'>
            <img
              src={props.fontStyleViewFormData.image}
              alt='Thumbnail'
              style={{ width: '100px', height: '100px' }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant='outlined' onClick={props.onHide}>
          Back
        </Button> */}
      </Modal.Footer>
    </Modal>
  )
}
