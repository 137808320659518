import $ from 'jquery'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useCallback, useEffect, useState } from 'react'
import 'react-dropdown/style.css'

export const EditBeads = props => {
  const [beadsAmount, setBeadsAmount] = useState()
  useEffect(() => {}, [props?.show === true])

  const handleSubmit = () => {
    props?.onSubmit()
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          Update Beads
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span> Total Balance: 100</span>
        <div className='row'>
          <div className='col-md-6'>
            <Form.Control
              value={beadsAmount}
              onChange={e => setBeadsAmount(e.target.value)}
              placeholder='Amount'
              aria-label='Small'
              aria-describedby='inputGroup-sizing-sm'
            />
            {beadsAmount ? (
              <></>
            ) : (
              <>
                <label className='custom-error-label'>
                  Amount is required*
                </label>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
