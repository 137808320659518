import Dropdown from 'react-bootstrap/Dropdown'
import { useDispatch } from 'react-redux'
import InputGroup from 'react-bootstrap/InputGroup'
import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import AddIcon from '@mui/icons-material/Add'
import { updateTopHeader } from '../../../redux/features/common/commonSlice'
import { useEffect, useState } from 'react'
import { RegularStreamingTable } from './RegularStreamingTable'
import { PaidStreamingTable } from './PaidStreamingTable'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export const StreamingList = () => {
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [tabValue, setTabValue] = React.useState(0)
  const [value, setValue] = useState(dayjs())
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateTopHeader('Streaming Manager'))
  })

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Streaming List</h3>
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-3'>
                      <InputGroup
                        className='mb-2'
                        style={{ borderRadius: '10px' }}
                      >
                        <Form.Control
                          placeholder='Search by Keyword'
                          aria-label='Amount (to the nearest dollar)'
                        />
                        <InputGroup.Text
                          style={{
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px'
                          }}
                        >
                          <SearchIcon />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className='col-md-1 mb-2 '>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {statusDropdownText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setStatusDropdownText('Select Status')
                            }
                          >
                            Select Status
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Active')}
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('In Active')}
                          >
                            In Active
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className='col-md-4 mb-3'
                      style={{ marginLeft: '20px' }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={value}
                            onChange={newValue => setValue(newValue)}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={value}
                            onChange={newValue => setValue(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3 mb-2'>
                      <Button variant='contained' className='custom-filter-btn'>
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor='secondary'
                        textColor='inherit'
                        variant='fullWidth'
                        aria-label='full width tabs example'
                      >
                        <Tab label='Regular Streaming' {...a11yProps(0)} />
                        <Tab label='Paid(Event) Streaming' {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </div>
                </div>
                {tabValue === 0 ? (
                  <RegularStreamingTable />
                ) : (
                  <PaidStreamingTable />
                )}
                <div className='card-footer py-4'>
                  <nav aria-label='...'>
                    <ul className='pagination justify-content-end mb-0'>
                      <li className='page-item disabled'>
                        <a className='page-link' href='#' tabIndex={-1}>
                          <i className='fas fa-angle-left' />
                          <span className='sr-only'>Previous</span>
                        </a>
                      </li>
                      <li className='page-item active'>
                        <a className='page-link' href='#'>
                          1
                        </a>
                      </li>
                      <li className='page-item'>
                        <a className='page-link' href='#'>
                          2 <span className='sr-only'>(current)</span>
                        </a>
                      </li>
                      <li className='page-item'>
                        <a className='page-link' href='#'>
                          3
                        </a>
                      </li>
                      <li className='page-item'>
                        <a className='page-link' href='#'>
                          <i className='fas fa-angle-right' />
                          <span className='sr-only'>Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
