import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import ApiCaller from '../../constants/ApiCaller'
import { useDispatch, useSelector } from 'react-redux'
import urls from '../../constants/apiurl'
import { showDangerToast, showToast } from '../../constants/toaster'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const GlobalSettingList = (props) => {
  const [formValues, setFormValues] = useState({})
  const userData = useSelector((state) => state?.userData)
  const [datalist, setDatalist] = useState({})
  const [id, setId] = useState('')
  const dispatch = useDispatch()

  const handleInputChange = (e, key) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '')
    setDatalist((prevData) => ({
      ...prevData,
      [key]: newValue
    }))
  }
  const setSettingData = async () => {
    console.log(datalist)
    if (
      (datalist?.subscriberChannelMaxPrice &&
        datalist?.subscriberChannelMinPrice &&
        datalist?.subscriberChannelMaxPrice <
          datalist?.subscriberChannelMinPrice) ||
      (datalist?.eventPriceMaxBeads &&
        datalist?.eventPriceMinBeads &&
        datalist?.eventPriceMaxBeads < datalist?.eventPriceMinBeads) ||
      (datalist?.videoMaximumSeconds &&
        datalist?.videoMinimumSeconds &&
        datalist?.videoMaximumSeconds < datalist?.videoMinimumSeconds) ||
      (datalist?.maximumBuyBeads &&
        datalist?.minimumBuyBeads &&
        datalist?.maximumBuyBeads < datalist?.minimumBuyBeads)
    ) {
      showDangerToast('Please fill correction data.')
    } else {
      try {
        dispatch(uplodateLoading(true))
        let path = urls.setGlobalSetting
        let res = await ApiCaller.post(datalist, path, userData?.access_token)
        const response = await res.response.json()
        if (response.status == 200) {
          dispatch(uplodateLoading(false))
          setDatalist(response?.data)
          showToast(response?.message)
        } else {
          dispatch(uplodateLoading(false))
          showDangerToast(response.message)
        }
      } catch (error) {
        dispatch(uplodateLoading(false))
        console.error(error)
        showDangerToast(error.message)
      }
    }
  }
  useEffect(() => {
    // dispatch(updateTopHeader("Global Setting"));
  }, [])
  useEffect(() => {
    getSettingList()
  }, [])

  const getSettingList = async () => {
    try {
      dispatch(uplodateLoading(true))

      let path = urls.getGlobalSetting
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Global Setting</h3>
                    </div>
                    <div className='col text-right'>
                      <button
                        onClick={setSettingData}
                        className='btn btn-sm btn-primary text-white'
                        disabled={
                          (datalist?.subscriberChannelMaxPrice &&
                            datalist?.subscriberChannelMinPrice &&
                            datalist?.subscriberChannelMaxPrice <
                              datalist?.subscriberChannelMinPrice) ||
                          (datalist?.eventPriceMaxBeads &&
                            datalist?.eventPriceMinBeads &&
                            datalist?.eventPriceMaxBeads <
                              datalist?.eventPriceMinBeads) ||
                          (datalist?.videoMaximumSeconds &&
                            datalist?.videoMinimumSeconds &&
                            datalist?.videoMaximumSeconds <
                              datalist?.videoMinimumSeconds) ||
                          (datalist?.maximumWithdrawBeads &&
                            datalist?.minimumWithdrawBeads &&
                            datalist?.maximumWithdrawBeads <
                              datalist?.minimumWithdrawBeads) ||
                          (datalist?.maximumBuyBeads &&
                            datalist?.minimumBuyBeads &&
                            datalist?.maximumBuyBeads <
                              datalist?.minimumBuyBeads)
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Inactive User:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Video Uploading Time:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Profile</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfProfileForReport}
                            placeholder='Enter report no. for profile'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfProfileForReport']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Video </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.noOfVideoForReport}
                            placeholder='Enter report no. for Video'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['noOfVideoForReport']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.videoMaximumSeconds &&
                        datalist?.videoMinimumSeconds &&
                        (parseInt(datalist?.videoMaximumSeconds) <
                        parseInt(datalist?.videoMinimumSeconds)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Sec)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.videoMinimumSeconds}
                            placeholder='Enter Minimum Seconds'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  videoMinimumSeconds: parseInt(e.target.value)
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Sec)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.videoMaximumSeconds}
                            placeholder='Enter Maximum Seconds'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  videoMaximumSeconds: parseInt(e.target.value)
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.videoMaximumSeconds &&
                          datalist?.videoMinimumSeconds &&
                          parseInt(datalist?.videoMaximumSeconds) <
                            parseInt(datalist?.videoMinimumSeconds) && (
                            <label className='custom-error-label'>
                              Minimum should be less then Maximum*
                            </label>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Withdraw Limit:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Sign up Beads:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.maximumWithdrawBeads != null &&
                        datalist?.minimumWithdrawBeads != null &&
                        (parseInt(datalist?.maximumWithdrawBeads) <
                        parseInt(datalist?.minimumWithdrawBeads)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maximumWithdrawBeads}
                            placeholder='Enter Maximum Withdraw Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  maximumWithdrawBeads: parseInt(e.target.value)
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minimumWithdrawBeads}
                            placeholder='Enter Minimum Withdraw Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  minimumWithdrawBeads: parseInt(e.target.value)
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.maximumWithdrawBeads != null &&
                        datalist?.minimumWithdrawBeads != null &&
                        parseInt(datalist?.maximumWithdrawBeads) <
                          parseInt(datalist?.minimumWithdrawBeads) ? (
                          <label className='custom-error-label'>
                            Minimum should be less then Maximum*
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>No of Beads </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.freeBeadsAfterRegistration}
                            placeholder='Enter Free Beads given after registration'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['freeBeadsAfterRegistration']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Buy Beads Limit:</h4>
                  </div>
                  <div className='col-md-1'></div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.maximumBuyBeads != null &&
                        datalist?.minimumBuyBeads != null &&
                        (parseInt(datalist?.maximumBuyBeads) <
                        parseInt(datalist?.minimumBuyBeads)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Maximum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.maximumBuyBeads}
                            placeholder='Enter Maximum Withdraw Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  maximumBuyBeads: parseInt(e.target.value)
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Minimum(Amount)</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.minimumBuyBeads}
                            placeholder='Enter Minimum Withdraw Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  minimumBuyBeads: parseInt(e.target.value)
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.maximumBuyBeads != null &&
                        datalist?.minimumBuyBeads != null &&
                        parseInt(datalist?.maximumBuyBeads) <
                          parseInt(datalist?.minimumBuyBeads) ? (
                          <label className='custom-error-label'>
                            Minimum should be less then Maximum*
                          </label>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                </div>

                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Admin Commission on Withdraw:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Beads Pricing:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Enter % of commission</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={3}
                            value={datalist?.percentageOfCommission}
                            placeholder='Enter % of commission'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['percentageOfCommission']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{
                      border: '1px solid #f2e2e2',
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center'
                    }}
                  >
                    <div className='beads-info'>
                      Number of Beads
                      <span className='beads-amount'>
                        <input
                          maxLength={7}
                          value={datalist?.numberOfBeadsEqual}
                          onChange={(e) =>
                            handleInputChange(e, 'numberOfBeadsEqual')
                          }
                          type='text'
                          className='custom-input'
                          placeholder=''
                        />
                      </span>
                      is equal to
                      <span className='ngn-amount'>
                        <input
                          maxLength={7}
                          value={datalist?.amountOfNGNEqual}
                          onChange={(e) =>
                            handleInputChange(e, 'amountOfNGNEqual')
                          }
                          type='text'
                          className='custom-input'
                          placeholder=''
                        />
                        NGN
                      </span>
                      .
                    </div>
                  </div>
                </div>

                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Event Price:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Ad View Time:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.eventPriceMaxBeads != null &&
                        datalist?.eventPriceMinBeads != null &&
                        (parseInt(datalist?.eventPriceMaxBeads) <
                        parseInt(datalist?.eventPriceMinBeads)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Min Beads</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.eventPriceMinBeads}
                            placeholder='Enter Min Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['eventPriceMinBeads']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Max Beads</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.eventPriceMaxBeads}
                            placeholder='Enter Max Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['eventPriceMaxBeads']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.eventPriceMaxBeads != null &&
                          datalist?.eventPriceMinBeads != null &&
                          parseInt(datalist?.eventPriceMaxBeads) <
                            parseInt(datalist?.eventPriceMinBeads) && (
                            <label className='custom-error-label'>
                              Minimum should be less then Maximum*
                            </label>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Sec/View </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={5}
                            value={datalist?.perViewSec}
                            placeholder='Enter Sec/View'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['perViewSec']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row' style={{ margin: '10px' }}>
                  <div className='col-md-5'>
                    <h4>Subscriber Channel Price/Month:</h4>
                  </div>
                  <div className='col-md-1'></div>
                  <div className='col-md-5'>
                    <h4>Badge Price:</h4>
                  </div>
                </div>
                <div className='row' style={{ marginLeft: '25px' }}>
                  <div
                    className='col-md-5'
                    style={{
                      border:
                        datalist?.subscriberChannelMaxPrice != null &&
                        datalist?.subscriberChannelMinPrice != null &&
                        (parseInt(datalist?.subscriberChannelMaxPrice) <
                        parseInt(datalist?.subscriberChannelMinPrice)
                          ? '1px solid red'
                          : '1px solid #f2e2e2')
                    }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Min Beads</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.subscriberChannelMinPrice}
                            placeholder='Enter Min Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['subscriberChannelMinPrice']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Max Beads</Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.subscriberChannelMaxPrice}
                            placeholder='Enter Max Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['subscriberChannelMaxPrice']: parseInt(
                                    e.target.value
                                  )
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                      <div className='col-md-12 text-center'>
                        {datalist?.subscriberChannelMaxPrice != null &&
                          datalist?.subscriberChannelMinPrice != null &&
                          parseInt(datalist?.subscriberChannelMaxPrice) <
                            parseInt(datalist?.subscriberChannelMinPrice) && (
                            <label className='custom-error-label'>
                              Minimum should be less then Maximum*
                            </label>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-1'></div>
                  <div
                    className='col-md-5'
                    style={{ border: '1px solid #f2e2e2' }}
                  >
                    <div className='row p-2'>
                      <div className='col-md-6'>
                        <Form.Group
                          className='mb-3'
                          controlId='exampleForm.ControlInput1'
                        >
                          <Form.Label>Beads </Form.Label>
                          <Form.Control
                            type='text'
                            maxLength={8}
                            value={datalist?.badgeBeadsPrice}
                            placeholder='Enter Beads'
                            className='form-control'
                            onChange={(e) => {
                              // Use a regular expression to check if the input is numeric
                              const numericRegex = /^[0-9\b]+$/
                              // Check if the input value matches the numeric regex
                              if (
                                numericRegex.test(e.target.value) ||
                                e.target.value === ''
                              ) {
                                // Update the state only if the input is numeric or empty
                                setDatalist((prevData) => ({
                                  ...prevData,
                                  ['badgeBeadsPrice']: e.target.value
                                }))
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-5'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
