import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { showDangerToast } from '../../constants/toaster'
export const AddTag = props => {
  const handleSubmit = () => {
    if (props?.tagFormData?.name) {
      props.onSubmit()
    } else {
      showDangerToast('Please fill all required Fields')
    }
  }

  const handleTagName=(e)=>{
    let value = e.target.value.replace(/\s{2,}/g, ' ')
    props.setTagFormData({
      ...props?.tagFormData,
      name: value
    })
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.tagFormData?.id ? 'Update Interest/Tag' : 'Add Interest/Tag'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          value={props.tagFormData.name}
          onChange={e =>handleTagName(e) }
          placeholder='Enter Interest/Tag Name'
          aria-label='Small'
          aria-describedby='inputGroup-sizing-sm'
        />
        {props?.tagFormData?.name ? (
          <></>
        ) : (
          <>
            <label className='custom-error-label'>Interest/Tag Name is required*</label>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
