import Switch from '@mui/material/Switch'
import Swal from 'sweetalert2'
import NoDataImg from '../../../assets/img/no-data.gif'
import Pagination from '@mui/material/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { AddSound } from './AddSound'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { ViewSound } from './ViewSound'
import Utils from '../../constants/Utils'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const SoundList = (props) => {
  const LOADING_ENABLE = useSelector((state) => state?.loading)
  const [usedDropdownText, setUsedDropdownText] = useState('ALL')
  const [inputValue, setInputValue] = useState('')
  const [tags, setTags] = useState([])
  const [value, setValue] = useState(dayjs())
  const [searchText, setSearchText] = useState('')
  const [searchTextPlaylist, setSearchTextPlaylist] = useState('')
  const [startDate, setStartDate] = useState('MM/DD/YYYY')
  const [endDate, setEndDate] = useState('MM/DD/YYYY')
  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [thumbnailFile, setThumbnailFile] = useState()
  const [soundFile, setSoundFile] = useState()
  const dispatch = useDispatch()
  const [datalist, setDatalist] = useState([])
  const [defaultWidgetOption, setDefaultWidgetOption] = useState(null)
  const [widgetKeyValueData, setWidgetKeyValueData] = useState([])
  const [showAddFilter, setShowAddFilter] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector((state) => state?.userData)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [filterFileControl, setFilterFileControl] = useState({
    showError: false,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [showAddSound, setShowAddSound] = useState(false)
  const [soundFormData, setSoundFormData] = useState({
    id: '',
    name: '',
    displayName: '',
    playListName: '',
    duration: '',
    artistName: '',
    imageUrl: '',
    widgetId: '',
    widgetName: '',
    soundUrl: '',
    description: ''
  })

  const [showViewEffect, setShowViewEffect] = useState(false)
  const [effectViewFormData, setEffectViewFormData] = useState({
    name: '',
    displayName: '',
    playListName: '',
    duration: 0,
    artistName: '',
    imageUrl: '',
    widgetName: '',
    soundUrl: '',
    description: '',
    keyWords: '',
    active: '',
    createdAt: '',
    updatedAt: '',
    noOfTimeUsed: 0
  })

  const handleFilterClick = () => {
    getSoundList(searchText, searchTextPlaylist, startDate, endDate)
  }

  const handleResetFilter = () => {
    setStartDate('MM/DD/YYYY')
    setEndDate('MM/DD/YYYY')
    setSearchText('')
    getSoundList('')
    setSearchTextPlaylist('')
    setUsedDropdownText('ALL')
  }

  const getWidgetKeyValuePair = async (roleId) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.widgetKeyValuePair
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        let tempWidgetKeyValueList = [{ value: '', label: 'Select Widget' }]
        response?.data?.map((data, i) => {
          tempWidgetKeyValueList.push({ value: data?.value, label: data?.key })
          if (data?.value === roleId) {
            setDefaultWidgetOption({ value: data?.value, label: data?.key })
          }
        })
        setWidgetKeyValueData(tempWidgetKeyValueList)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleOnSubmitSoundModel = async () => {
    try {
      let postJson = {
        name: soundFormData?.name,
        displayName: soundFormData?.displayName,
        playListName: soundFormData?.playListName,
        duration: soundFormData?.duration,
        artistName: soundFormData?.artistName,
        widgetId: soundFormData?.widgetId,
        description: soundFormData?.description,
        keyWords: tags,
        id: soundFormData?.id
      }
      let form_data = new FormData()
      form_data.append(
        'soundDto',
        new Blob([JSON.stringify(postJson)], {
          type: 'application/json'
        })
      )
      form_data.append('image', thumbnailFile)
      form_data.append('sound', soundFile)
      dispatch(uplodateLoading(true))
      let path = urls.addSound
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        setShowAddSound(false)
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getSoundList()
        setTags([])
        setThumbnailFile(null)
        setSoundFile(null)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleViewEffect = (id) => {
    getEffectWithId(id)
  }

  const getEffectWithId = async (id) => {
    try {
      dispatch(uplodateLoading(true))

      let path = urls.getSound + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setEffectViewFormData((prevState) => {
          return {
            ...prevState,
            ...response.data
          }
        })
        setShowViewEffect(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeSoundStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getSoundList(searchText, searchTextPlaylist, startDate, endDate)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleAddSound = () => {
    getWidgetKeyValuePair()
    setSoundFormData({
      id: '',
      name: '',
      displayName: '',
      playListName: '',
      duration: '',
      artistName: '',
      imageUrl: '',
      widgetId: '',
      widgetName: '',
      soundUrl: '',
      description: ''
    })
    setShowAddSound(true)
  }
  const handleEditSound = (item) => {
    console.log('itemitemitemitemitemitemitemitem', item)
    getWidgetKeyValuePair(item?.widgetId)
    setSoundFormData(item)
    setTags(item?.keyWords)
    setShowAddSound(true)
    setThumbnailFile(item.imageUrl)
    setSoundFile(item?.soundUrl)
  }

  const handleHideModel = () => {
    setShowAddSound(false)
    setThumbanailControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
    setFilterFileControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
    setInputValue('')
    setTags([])
  }
  const handleDeleteSound = (data) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async (id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteSound + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getSoundList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    dispatch(updateTopHeader('Sound List'))
  })

  useEffect(() => {
    getSoundList(searchText, searchTextPlaylist, startDate, endDate)
  }, [sort_order, sort_type, usedDropdownText, page, props])

  const getSoundList = async (
    searchTextRef,
    searchTextPlaylistRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        searchPlayListText: searchTextPlaylistRef,
        pageNumber: pageNo - 1 || 0,
        used: usedDropdownText,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== 'MM/DD/YYYY'
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== 'MM/DD/YYYY' ? endDateRef.toDate() : ''
      }
      let path = urls.soundList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Sound List</h3>
                      </div>
                      <div className='col text-right'>
                        {userData.admin ? (
                          <a
                            onClick={handleAddSound}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add Sound
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddSound}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add Sound
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Artist Name'
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by Playlist'
                            value={searchTextPlaylist}
                            onChange={(e) =>
                              setSearchTextPlaylist(e.target.value)
                            }
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {usedDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setSortType('createdAt')
                                setUsedDropdownText('ALL')
                                setSortOrder(false)
                              }}
                            >
                              Select
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setUsedDropdownText('MOST')
                                setSortType('usedTIme')
                                setSortOrder(false)
                              }}
                            >
                              Most
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setUsedDropdownText('LEAST')
                                setSortType('usedTIme')
                                setSortOrder(true)
                              }}
                            >
                              Least
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={(newValue) => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={(newValue) => setEndDate(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist?.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S.No</th>
                              <th scope='col'>Sounds name</th>
                              <th scope='col'>Playlist Name</th>
                              <th scope='col'>Artist Name</th>
                              <th scope='col'>Added on</th>
                              <th scope='col'>Number of times being used</th>
                              <th scope='col'>Status</th>
                              <th scope='col'>Thumbnail</th>
                              <th scope='col' />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{data?.name}</td>
                                      <td>{data?.playListName}</td>
                                      <td>{data?.artistName}</td>
                                      <td>
                                        {Utils.formatDate(data?.createdAt)}
                                      </td>
                                      <td>{data?.noOfTimeUsed}</td>
                                      <td>
                                        {userData.admin ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={(e) =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : active ? (
                                          <Switch
                                            {...label}
                                            checked={data?.active}
                                            onChange={(e) =>
                                              handleStatusChange(e, data?.id)
                                            }
                                            size='small'
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                      <td>
                                        <Image
                                          image={{
                                            id: 1,
                                            src: data?.imageUrl
                                          }}
                                          width='80px'
                                          height='auto'
                                        />
                                      </td>

                                      <td className='text-right'>
                                        <div className='dropdown'>
                                          <a
                                            className='btn btn-sm btn-icon-only text-light'
                                            href='#'
                                            role='button'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                          >
                                            <i className='fas fa-ellipsis-v' />
                                          </a>
                                          <div
                                            className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                            x-placement='bottom-end'
                                            style={{
                                              position: 'absolute',
                                              willChange: 'transform',
                                              top: '0px',
                                              left: '0px',
                                              transform:
                                                'translate3d(32px, 32px, 0px)'
                                            }}
                                          >
                                            {/* <a
                                              className='dropdown-item'
                                              onClick={() =>
                                                handleViewEffect(data.id)
                                              }
                                            >
                                              View
                                            </a> */}

                                            {userData.admin ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleEditSound(data)
                                                }
                                              >
                                                Edit
                                              </a>
                                            ) : edit ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleEditSound(data)
                                                }
                                              >
                                                Edit
                                              </a>
                                            ) : (
                                              <></>
                                            )}
                                            {userData.admin ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleDeleteSound(data)
                                                }
                                              >
                                                Delete
                                              </a>
                                            ) : deletePermission ? (
                                              <a
                                                className='dropdown-item cursor'
                                                onClick={() =>
                                                  handleDeleteSound(data)
                                                }
                                              >
                                                Delete
                                              </a>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? (
                              <>Loading Data.....</>
                            ) : (
                              <>No Data Found</>
                            )}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist?.length > 0 ? (
                    <>
                      <div className='card-footer py-4'>
                        <Pagination
                          className='custom-pagination-class'
                          count={pagerecord?.totalPages}
                          page={page}
                          onChange={handleChange}
                          color='secondary'
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>

          <ViewSound
            show={showViewEffect}
            effectViewFormData={effectViewFormData}
            onHide={() => setShowViewEffect(false)}
          />
          <AddSound
            tags={tags}
            setTags={setTags}
            show={showAddSound}
            soundFormData={soundFormData}
            widgetKeyValueData={widgetKeyValueData}
            defaultWidgetOption={defaultWidgetOption}
            setSoundFormData={setSoundFormData}
            onSubmit={() => handleOnSubmitSoundModel()}
            onHide={() => handleHideModel()}
            setSoundFile={setSoundFile}
            setThumbnailFile={setThumbnailFile}
            thumbnailFile={thumbnailFile}
            soundFile={soundFile}
            thumbanailControl={thumbanailControl}
            setThumbanailControl={setThumbanailControl}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setFilterFileControl={setFilterFileControl}
            filterFileControl={filterFileControl}
          />
        </div>
      </>
    </>
  )
}
