import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch } from 'react-redux'
import { updateTopHeader } from '../../../redux/features/common/commonSlice'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const ProfileAbuseTable = ({ setShowAbuseReportDetails }) => {
  const [statusDropdownText, setStatusDropdownText] = useState('Profile')
  const [value, setValue] = useState(dayjs())
  const dispatch = useDispatch()
  const [showAddFontStyle, setShowAddFontStyle] = useState(false)
  const [fontStyleFormData, setFontStyleFormData] = useReducer(
    (fontStyleFormData, newItem) => {
      return { ...fontStyleFormData, ...newItem }
    },
    {
      id: '',
      assetName: '',
      type: '',
      description: '',
      coverImageUrl: '',
      remotePackageUrl: ''
    }
  )
  const handleOnSubmitFilterModel = () => {}
  const handleAddFilter = () => {
    setFontStyleFormData({})
    setShowAddFontStyle(true)
  }
  const handleEditFilter = item => {
    alert()
  }

  useEffect(() => {
    dispatch(updateTopHeader('Report & Abuse Manager'))
  })

  return (
    <>
      <div className='table-responsive'>
        <table className='table align-items-center table-flush'>
          <thead className='thead-light'>
            <tr>
              <th scope='col'>S/No.</th>
              <th scope='col'>User Name</th>
              <th scope='col'>UserID</th>
              <th scope='col'>Profile Name</th>
              <th scope='col'>Profile Link</th>
              <th scope='col'>Number of Reports</th>
              <th scope='col'>Status</th>
              <th scope='col' />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Cluch--SK</td>
              <td>1001</td>
              <td>Suresh Kumar</td>
              <td>http://circo.com/profile/1001</td>
              <td>20</td>
              <td>
                <Switch {...label} defaultChecked size='small' />
              </td>
              <td className='text-right'>
                <div className='dropdown'>
                  <a
                    className='btn btn-sm btn-icon-only text-light'
                    href='#'
                    role='button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <i className='fas fa-ellipsis-v' />
                  </a>
                  <div
                    className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                    x-placement='bottom-end'
                    style={{
                      position: 'absolute',
                      willChange: 'transform',
                      top: '0px',
                      left: '0px',
                      transform: 'translate3d(32px, 32px, 0px)'
                    }}
                  >
                    <a
                      className='dropdown-item cursor'
                      onClick={() => setShowAbuseReportDetails(true)}
                    >
                      View Reports
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
