import { createContext, useContext, useEffect, useState } from 'react'
import { RenderHeader } from '../components/structure/Header'
import { RenderRoutes } from '../components/structure/RenderNavigation'
import { useDispatch } from 'react-redux'
import { updateUserData } from '../redux/features/common/commonSlice'

const AuthContext = createContext()

export const AuthData = () => useContext(AuthContext)

export const AuthWrapper = () => {
  const dispatch = useDispatch()
  const [user, setUser] = useState({ name: '', isAuthenticated: false })

  const login = (userName, password) => {
    return new Promise((resolve, reject) => {
      setUser({ name: userName, isAuthenticated: true })
      resolve('success')
    })
  }
  // useEffect(() => {
  //   let storeData = localStorage.getItem('userData')
  //   if (storeData && storeData != null && storeData !== undefined) {
  //     storeData = JSON.parse(storeData)
  //     dispatch(updateUserData(storeData))
  //   }
  // }, [])

  const logout = () => {
    localStorage.removeItem('userData')
    localStorage.removeItem('userToken')
    dispatch(updateUserData({}))
    setUser({ ...user, isAuthenticated: false })
    window.location.href = '/login'
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <>
        <RenderRoutes />
      </>
    </AuthContext.Provider>
  )
}
