import Swal from 'sweetalert2'
import Switch from '@mui/material/Switch'
import NoDataImg from '../../../assets/img/no-data.gif'
import Pagination from '@mui/material/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { AddWidget } from './AddWidget'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import { ViewWidget } from './ViewWidget'
import Utils from '../../constants/Utils'

const label = { inputProps: { 'aria-label': 'Size switch demo' } }

const defaultWidgetOptions = [
  { value: '', label: 'Select Widget Type' },
  { value: 'CIRCULAR', label: 'Circular' }
]
const defaultWidgetContentTypeOptions = [
  { value: '', label: 'Select Widget Content Type' },
  { value: 'EFFECTS_AND_FILTERS', label: 'Effects & Filters' },
  { value: 'FILTERS', label: 'Filters' },
  { value: 'SOUND', label: 'Sound' }
]

export const WidgetList = props => {
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState('')
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
    getWidgetsList(value)
  }
  const [thumbnailFile, setThumbnailFile] = useState()
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [startDate, setStartDate] = useState('MM/DD/YYYY')
  const [endDate, setEndDate] = useState('MM/DD/YYYY')
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector(state => state?.userData)
  const LOADING_ENABLE = useSelector(state => state?.loading)
  const [datalist, setDatalist] = useState([])
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [thumbanailControl, setThumbanailControl] = useState({
    showError: true,
    errorMessage: '',
    showImage: false,
    imageData: null
  })
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const dispatch = useDispatch()
  const [defaultWidgetType, setDefaultWidgetType] = useState({
    value: '',
    label: 'Select Widget Type'
  })
  const [defaultWidgetContentType, setDefaultWidgetContentType] = useState({
    value: '',
    label: 'Select Widget Content Type'
  })
  const [showAddWidget, setShowAddWidget] = useState(false)
  const [widgetFormData, setWidgetFormData] = useState({
    id: '',
    widgetName: '',
    widgetType: '',
    position: 0,
    widgetContentType: ''
  })

  const [showViewEffect, setShowViewEffect] = useState(false)
  const [effectViewFormData, setEffectViewFormData] = useState({
    widgetName: '',
    widgetType: '',
    position: 0,
    widgetContentType: '',
    thumbnail: '',
    isActive: ''
  })

  const handleViewEffect = id => {
    getEffectWithId(id)
  }

  const getEffectWithId = async id => {
    try {
      dispatch(uplodateLoading(true))

      let path = urls.getWidget + '/' + id
      let res = await ApiCaller.get({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setEffectViewFormData(prevState => {
          return {
            ...prevState,
            ...response.data
          }
        })
        setShowViewEffect(true)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }
  const handleOnSubmitFilterModel = async () => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        id: widgetFormData.id,
        widgetName: widgetFormData.widgetName,
        widgetType: widgetFormData.widgetType,
        position: widgetFormData.position,
        widgetContentType: widgetFormData.widgetContentType
      }
      let form_data = new FormData()
      form_data.append(
        'widgetRequest',
        new Blob([JSON.stringify(postJson)], {
          type: 'application/json'
        })
      )
      form_data.append('thumbnail', thumbnailFile)
      let path = urls.addWidget
      var res = await ApiCaller.post_formdata(
        form_data,
        path,
        userData?.access_token
      )
      const response = await res.response.json()
      if (response.status == 200) {
        setShowAddWidget(false)
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getWidgetsList()
        setThumbnailFile(null)
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleFilterClick = () => {
    getWidgetsList(searchText, startDate, endDate)
  }


  const handleResetFilter = () => {
    setStartDate('MM/DD/YYYY')
    setEndDate('MM/DD/YYYY')
    setSearchText('')
    getWidgetsList('', 'MM/DD/YYYY', 'MM/DD/YYYY')
  }
  const handleAddFilter = () => {
    setWidgetFormData({
      id: '',
      widgetName: '',
      widgetType: '',
      position: 0,
      widgetContentType: ''
    })
    setDefaultWidgetType({
      value: '',
      label: 'Select Widget Type'
    })
    setDefaultWidgetContentType({
      value: '',
      label: 'Select Widget Content Type'
    })
    setShowAddWidget(true)
  }

  const handleDeleteWidget = data => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteWidget + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getWidgetsList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditWidget = item => {
    console.log('item', item)
    setWidgetFormData(item)
    if (item?.thumbnail) {
      setThumbnailFile(item?.thumbnail)
    }
    defaultWidgetContentTypeOptions?.map(data => {
      if (data?.value === item?.widgetContentType) {
        setDefaultWidgetContentType({
          value: data?.value,
          label: data?.label
        })
      }
    })
    defaultWidgetOptions?.map(data => {
      if (data?.value === item?.widgetType) {
        setDefaultWidgetType({
          value: data?.value,
          label: data?.label
        })
      }
    })
    setShowAddWidget(true)
  }

  const handleStatusChange = async (event, id) => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.changeWidgetStatus + '/' + id
      let res = await ApiCaller.put({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        showToast(response?.message)
        getWidgetsList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleHideModel = () => {
    setShowAddWidget(false)
    setThumbanailControl({
      showError: true,
      errorMessage: '',
      showImage: false,
      imageData: null
    })
  }




  const getWidgetsList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== 'MM/DD/YYYY'
            ? new Date(startDateRef)
            : '',
        endDate:
          endDateRef && endDateRef !== 'MM/DD/YYYY' ? endDateRef.toDate() : ''
      }
      console.log("12ajksndjkasndjk",postJson)
      console.log('Start Date:', startDate);
      console.log('End Date:', endDate);
      let path = urls.widgetList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)

        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    dispatch(updateTopHeader('Widgets List'))
  }, [])

  useEffect(() => {
    getWidgetsList("", startDate, endDate)
  }, [sort_order, sort_type, page, props])

  useEffect(() => {
    getWidgetsList("", startDate, endDate);
  }, [startDate, endDate, statusDropdownText]);

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Widgets List</h3>
                      </div>
                      <div className='col text-right'>
                        {userData.admin ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add Widget
                          </a>
                        ) : edit ? (
                          <a
                            onClick={handleAddFilter}
                            className='btn btn-sm btn-primary text-white'
                          >
                            <AddIcon />
                            Add Widget
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by widget Name'
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>

                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={startDate}
                              onChange={newValue => setStartDate(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={endDate}
                              onChange={newValue => setEndDate(newValue)}
                              minDate={startDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                          onClick={handleFilterClick}
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                          onClick={handleResetFilter}
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    {datalist && datalist.length > 0 ? (
                      <>
                        <table className='table align-items-center table-flush'>
                          <thead className='thead-light'>
                            <tr>
                              <th scope='col'>S.No</th>
                              <th scope='col'>Widget Name</th>
                              <th scope='col'>Widget Type</th>
                              <th scope='col'>Position</th>
                              <th scope='col'>Created At</th>
                              <th scope='col'>Thumbnail</th>
                              <th scope='col'>Widget Content Type</th>
                              <th scope='col'>Status</th>
                              <th scope='col' />
                            </tr>
                          </thead>
                          <tbody>
                            {datalist &&
                              datalist?.map((data, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{data?.widgetName}</td>
                                    <td>{data?.widgetType}</td>
                                    <td>{data?.position}</td>
                                    <td>{/* {data?.createdAt} */}
                                      {Utils.formatDate(data?.createdAt)}</td>
                                    <td>
                                      <Image
                                        image={{
                                          id: 1,
                                          src: data?.thumbnail
                                        }}
                                        width='80px'
                                        height='auto'
                                      />
                                    </td>
                                    <td>{Utils.formatDate(data?.createdAt)}</td>
                                    <td>{data?.widgetContentType}</td>
                                    <td>
                                      {userData.admin ? (
                                        <Switch
                                          {...label}
                                          checked={data?.isActive}
                                          onChange={e =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : active ? (
                                        <Switch
                                          {...label}
                                          checked={data?.isActive}
                                          onChange={e =>
                                            handleStatusChange(e, data?.id)
                                          }
                                          size='small'
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className='text-right'>
                                      <div className='dropdown'>
                                        <a
                                          className='btn btn-sm btn-icon-only text-light'
                                          href='#'
                                          role='button'
                                          data-toggle='dropdown'
                                          aria-haspopup='true'
                                          aria-expanded='false'
                                        >
                                          <i className='fas fa-ellipsis-v' />
                                        </a>
                                        <div
                                          className='dropdown-menu dropdown-menu-right dropdown-menu-arrow'
                                          x-placement='bottom-end'
                                          style={{
                                            position: 'absolute',
                                            willChange: 'transform',
                                            top: '0px',
                                            left: '0px',
                                            transform:
                                              'translate3d(32px, 32px, 0px)'
                                          }}
                                        >
                                          {/* <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleViewEffect(data.id)
                                            }
                                          >
                                            View
                                          </a> */}

                                          {userData.admin ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleEditWidget(data)
                                              }
                                            >
                                              Edit
                                            </a>
                                          ) : edit ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleEditWidget(data)
                                              }
                                            >
                                              Edit
                                            </a>
                                          ) : (
                                            <></>
                                          )}
                                          {userData.admin ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleDeleteWidget(data)
                                              }
                                            >
                                              Delete
                                            </a>
                                          ) : deletePermission ? (
                                            <a
                                              className='dropdown-item cursor'
                                              onClick={() =>
                                                handleDeleteWidget(data)
                                              }
                                            >
                                              Delete
                                            </a>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <>
                        <div className='text-center'>
                          <img width={'25%'} src={NoDataImg}></img>
                          <br />
                          <label style={{ fontWeight: 'bold' }}>
                            {LOADING_ENABLE ? <>Loading Data.....</> : <>No Data Found</>}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                  {datalist && datalist.length > 0 ? (
                    <div className='card-footer py-4'>
                      <Pagination
                        className='custom-pagination-class'
                        count={pagerecord?.totalPages}
                        page={page}
                        onChange={handleChange}
                        color='secondary'
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/* <Footer /> */}
          </div>

          <ViewWidget
            show={showViewEffect}
            effectViewFormData={effectViewFormData}
            onHide={() => setShowViewEffect(false)}
          />
          <AddWidget
            show={showAddWidget}
            widgetFormData={widgetFormData}
            setWidgetFormData={setWidgetFormData}
            onSubmit={() => handleOnSubmitFilterModel()}
            onHide={() => handleHideModel()}
            setThumbnailFile={setThumbnailFile}
            thumbnailFile={thumbnailFile}
            defaultWidgetType={defaultWidgetType}
            defaultWidgetContentType={defaultWidgetContentType}
            defaultWidgetOptions={defaultWidgetOptions}
            defaultWidgetContentTypeOptions={defaultWidgetContentTypeOptions}
            thumbanailControl={thumbanailControl}
            setThumbanailControl={setThumbanailControl}
          />
        </div>
      </>
    </>
  )
}
