import Button from 'react-bootstrap/Button'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import Dropdown from 'react-dropdown'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const options = [
  { value: '', label: 'Select Ad Type' },
  { value: '1', label: 'Ganesh' }
]
const defaultOption = options[0]
const label = { inputProps: { 'aria-label': 'Size switch demo' } }

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

export const AddAds = props => {
  const [startDateChanged, setStartDateChanged] = useState(false)
  const [value, setValue] = useState(dayjs())
  const [isClickable, setIsClickable] = useState(false)
  const handleClickable = async event => {
    setIsClickable(event.target.checked)
  }

  const handleAdTypeChange = e => {
    props?.setAdsFormData({
      ...props?.adsFormData,
      type: e.value
    })
    props?.setAdsContentFile(null)
  }
  const handleImageSelect = event => {
    const fileInput = event.target
    const files = fileInput.files

    if (files.length > 0) {
      const selectedImage = files[0]
      props?.setAdsContentFile(selectedImage)
    }
  }

  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props?.adsFormData?.id ? 'Update Ads' : 'Add Ads'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props?.tabValue === 0 || props?.tabValue === 1 ? (
          <>
            {props?.tabValue === 0 ? (
              <div className='row'>
                <div className='col-md-12'>
                  <label>Ad Type*</label>
                  <Dropdown
                    options={props?.defaultAdsTypeOptions}
                    onChange={e => handleAdTypeChange(e)}
                    value={props?.defaultAdsType}
                    placeholder='Select Ad Type'
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {props?.adsFormData?.type === 'IMAGE' ||
            props?.adsFormData?.type === 'VIDEO' ||
            props?.tabValue === 1 ? (
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <input type='file' onChange={handleImageSelect} />
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <div className='row mt-3'>
          <div className='col-md-12'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Ads Title*</Form.Label>
              <Form.Control
                type='text'
                placeholder=''
                value={props?.adsFormData?.title}
                onChange={e =>
                  props?.setAdsFormData({
                    ...props?.adsFormData,
                    title: e.target.value
                  })
                }
              />
            </Form.Group>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>Start Date*</Form.Label>
              <DatePicker
                selected={props.adsFormData?.startDate}
                onChange={date => {
                  props?.setAdsFormData({
                    ...props?.adsFormData,
                    startDate: date
                  })
                  setStartDateChanged(true)
                }}
                dateFormat='MM/dd/yyyy'
                minDate={new Date()}
              />
            </Form.Group>
          </div>
          <div className='col-md-6'>
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>End Date*</Form.Label>
              <DatePicker
                selected={props.adsFormData?.endDate}
                onChange={date => {
                  props?.setAdsFormData({
                    ...props?.adsFormData,
                    endDate: date
                  })
                }}
                dateFormat='MM/dd/yyyy'
                minDate={props.adsFormData?.startDate}
                disabled={!startDateChanged}
              />
            </Form.Group>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-md-6'>
            <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
              <Form.Label>Views*</Form.Label>
              <Form.Control
                type='text'
                placeholder='Total Views Needed'
                value={props?.adsFormData?.viewsNeeded}
                onChange={e =>
                  props?.setAdsFormData({
                    ...props?.adsFormData,
                    viewsNeeded: e.target.value
                  })
                }
              />
            </Form.Group>
          </div>
          <div className='col-md-6 text-center' style={{ top: '35px' }}>
            <FormControlLabel
              value='start'
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked={props?.adsFormData?.urlEnabled}
                  onChange={e =>
                    props?.setAdsFormData({
                      ...props?.adsFormData,
                      urlEnabled: e.target.checked
                    })
                  }
                />
              }
              label='Clickable'
              labelPlacement='start'
            />
          </div>
        </div>
        {props?.adsFormData?.urlEnabled ? (
          <div className='row mt-3'>
            <div className='col-md-12'>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label>URL*</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='URL for the Ad'
                  value={props?.adsFormData?.url}
                  onChange={e =>
                    props?.setAdsFormData({
                      ...props?.adsFormData,
                      url: e.target.value
                    })
                  }
                />
              </Form.Group>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='outlined' onClick={props.onHide}>
         Cancel
        </Button>
        <Button onClick={props.onSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
  )
}
