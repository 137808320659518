import "react-dropdown/style.css";
import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export const ViewWidget = (props) => {
  console.log(props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Widget Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-1">
          <div className="col-md-6">
            Thumbnail:
            <img
              src={props.effectViewFormData.thumbnail}
              alt="Thumbnail"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>Widget Name: {props.effectViewFormData.widgetName}</p>
          </div>
          <div className="col-md-6">
            <p>Active: {props.effectViewFormData.isActive ? "Yes" : "No"}</p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p> Widget Type: {props.effectViewFormData.widgetType}</p>
          </div>
          <div className="col-md-6">
            <p> Widget Name: {props.effectViewFormData.widgetName}</p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>position: {props.effectViewFormData.position}</p>
          </div>
          <div className="col-md-6">
            <p>
              widgetContentType: {props.effectViewFormData.widgetContentType}
            </p>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-6">
            <p>Created At: {props.effectViewFormData.createdAt}</p>
          </div>
          <div className="col-md-6">
            <p>Updated At: {props.effectViewFormData.upDatedAt}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
