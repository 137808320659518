import Switch from '@mui/material/Switch'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const RegularStreamingTable = () => {
  return (
    <div className='table-responsive'>
      <table className='table align-items-center table-flush'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>S/No.</th>
            <th scope='col'>User Name</th>
            <th scope='col'>Name</th>
            <th scope='col'>Mobile No.</th>
            <th scope='col'>Joined User Count</th>
            <th scope='col'>Link</th>
            <th scope='col'>Time</th>
            <th scope='col'>Actions(Stop)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Cluch-GSK</td>
            <td>Jhon</td>
            <td>+91 9588034109</td>
            <td>15k</td>
            <td>http://localhost</td>
            <td>Live</td>
            <td>
              <Switch {...label} defaultChecked size='small' />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
