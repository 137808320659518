import { useDispatch, useSelector } from 'react-redux'
import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import NavBar from '../../structure/NavBar'
import { NavBarTop } from '../../structure/NavBarTop'
import AddIcon from '@mui/icons-material/Add'
import { updateTopHeader, uplodateLoading } from '../../../redux/features/common/commonSlice'
import { useEffect, useState } from 'react'
import { NormalUserFilters } from './NormalUserFilters'
import { PaidUserFilters } from './PaidUserFilters'
import { NormalUserTable } from './NormalUserTable'
import { PaidUserTable } from './PaidUserTable'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

export const UserList = (props) => {
  const [tabValue, setTabValue] = React.useState(0)
  const [paidSearchText, setPaidSearchText] = useState('')
  const [paidFilterClick, setPaidFilterClick] = useState(false)
  const [normalFilterClick, setNormalFilterClick] = useState(false)

  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }
  useEffect(() => {
    dispatch(updateTopHeader('User Manager'))
  })

  const handleFilterClick = () => {
    setPaidFilterClick(true)
  }

  const handleSetPaidSearchTxt = (e) => {
    setPaidSearchText(e)
    setPaidFilterClick(false)
  }
  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>User List</h3>
                    </div>
                  </div>
                </div>
                {tabValue === 0 ? <NormalUserFilters /> : <PaidUserFilters handleFilterClick={handleFilterClick} setPaidSearchText={handleSetPaidSearchTxt} paidSearchText={paidSearchText} />}
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        indicatorColor='secondary'
                        textColor='inherit'
                        variant='fullWidth'
                        aria-label='full width tabs example'
                      >
                        <Tab label='Normal User' {...a11yProps(0)} />
                        <Tab label='Booked User Name' {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                  </div>
                </div>
                {tabValue === 0 ? <NormalUserTable /> : <PaidUserTable paidFilterClick={paidFilterClick} paidSearchText={paidSearchText} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
