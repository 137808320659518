export const RenderHeader = () => {
  return (
    <div className='header'>
      <div className='logo'>
        <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'>
          <div className='container-fluid'>
            <div className='header-body'>
              {/* Card stats */}
              <div className='row'>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                            Total Number of Users
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Active/In Active : 350,897 / 23,00
                          </span>
                          <br />
                          {/* <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            In Active : 350,897
                          </span> */}
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-users' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Verification Badge
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Total Number:  350,897
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-certificate' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Subscribed Channel
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Total Number:  350,897
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-users' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Tip Received (NGN)
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Total Amount:  350,897 NGN
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Badge Verification Request
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Total :  350
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Uploaded Videos
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Total :  350
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Verified Users
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Total :  350
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Avg. Stay Time of user spending on the application
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Time :  6h
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Earning of Admin via beads (NGN)
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                            Total :  10,000 (NGN)
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 3.48%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Trending Video
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                          View received(in 24 Hours)) :  10M
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          Name Of Video
                        </span>
                        <span className='text-nowrap'>Tips of circo</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          Trending Sound
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                          Sound Used(in 24 Hours)) :  500k
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          Name Of Sound
                        </span>
                        <span className='text-nowrap'>Circo intro tune</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-6 mt-2'>
                  <div className='card card-stats mb-4 mb-xl-0'>
                    <div className='card-body text-left'>
                      <div className='row'>
                        <div className='col'>
                          <h5 className='card-title text-uppercase text-muted mb-0'>
                          App Rating
                          </h5>
                          <span className='h2  mb-0 text-white' style={{fontSize:'14px',fontWeight:'bold'}}>
                          5/5
                          </span>
                          <br />
                        </div>
                        <div className='col-auto'>
                          <div className='icon icon-shape bg-danger text-white rounded-circle shadow'>
                            <i className='fas fa-chart-bar' />
                          </div>
                        </div>
                      </div>
                      <p className='mt-3 mb-0 text-muted text-sm'>
                        <span className='text-success mr-2'>
                          <i className='fas fa-arrow-up' /> 2%
                        </span>
                        <span className='text-nowrap'>Since last week</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
