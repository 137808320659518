import Swal from 'sweetalert2'
import { useEffect, useReducer, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import NavBar from '../../structure/NavBar'
import Button from '@mui/material/Button'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { NavBarTop } from '../../structure/NavBarTop'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import AddIcon from '@mui/icons-material/Add'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast, showToast } from '../../constants/toaster'
import Switch from '@mui/material/Switch'
import { AddAds } from './AddAds'
import Utils from '../../constants/Utils'
import { MainAdsTable } from './MainAdsTable'
import { SmallAdsTable } from './SmallAdsTable'
import { InfluencerAdsTable } from './InfluencerAdsTable'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }

function a11yProps (index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  }
}

const defaultAdsTypeOptions = [
  { value: '', label: 'Select Ad Type' },
  { value: 'IMAGE', label: 'Image' },
  { value: 'VIDEO', label: 'Video' },
  { value: 'GOOGLE_MOB', label: 'Google Mob' }
]

export const AdsList = props => {
  const [adsContentFile, setAdsContentFile] = useState()
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState('MM/DD/YYYY')
  const [endDate, setEndDate] = useState('MM/DD/YYYY')
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [kycStatusDropdownText, setKycStatusDropdownText] =
    useState('Select Kyc Status')
  const [adTypeDropdownText, setAdTypeDropdownText] = useState('Select Type')
  const [addNewAds, setAddNewAds] = useState(false)
  const [value, setValue] = useState(dayjs())
  const userData = useSelector(state => state?.userData)
  const [showAddRole, setShowAddRole] = useState(false)
  const [datalist, setDatalist] = useState([])

  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const [viewPermissionControl, setViewPermissionControl] = useState(false)
  const [viewSubAdminList, setViewSubAdminList] = useState(false)
  const [sort_type, setSortType] = useState('createdAt')
  const [sort_order, setSortOrder] = useState(false)
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })

  const [tabValue, setTabValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  const [defaultAdsType, setDefaultAdsType] = useState({
    value: '',
    label: 'Select Ad Type'
  })
  const [adsFormData, setAdsFormData] = useState({
    id: '',
    title: '',
    type: '',
    adsType: 'MAIN_ADS',
    viewsNeeded: '',
    urlEnabled: false,
    url: '',
    startDate: new Date(),
    endDate: null
  })
  const [permissionSubmitData, setPermissionSubmitData] = useState([])
  const [rolePermissionData, setRolePermissionData] = useState({})
  const [subAdminListByRole, setSubAdminListByRole] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateTopHeader('Ad Management Manager'))
  }, [])
  useEffect(() => {
    getAdsList()
  }, [sort_order, sort_type, props, tabValue])

  const [page, setPage] = useState(1)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  const handleDeleteAds = data => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then(async result => {
      if (result.isConfirmed) {
        deleteApiCall(data?.id)
      }
    })
  }

  const deleteApiCall = async id => {
    try {
      dispatch(uplodateLoading(true))
      let path = urls.deleteAds + '/' + id
      let res = await ApiCaller.delete({}, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        Swal.fire('Deleted!', response?.message, 'success')
        getAdsList()
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  useEffect(() => {
    // This code will execute whenever startDate changes.
    getAdsList('', startDate, endDate, statusDropdownText)
  }, [startDate, endDate, statusDropdownText, adTypeDropdownText])

  const handleFilterClick = () => {
    getAdsList(searchText, startDate, endDate)
    console.log('Handle Filter Click')
  }

  const handleResetFilter = () => {
    setStartDate('MM/DD/YYYY')
    setEndDate('MM/DD/YYYY')
    setStatusDropdownText('Select Status')
    setAdTypeDropdownText('Select Type')
    setSearchText('')
    getAdsList('')
  }

  const getAdsList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        searchText: searchTextRef,
        pageNumber: pageNo - 1 || 0,
        pageSize: pagerecord.pageSize || 10,
        shortingField: sort_type,
        active:
          statusDropdownText === 'Active'
            ? true
            : statusDropdownText === 'In Active'
            ? false
            : null,
        adsType:
          tabValue === 0
            ? 'MAIN_ADS'
            : tabValue === 1
            ? 'SMALL_ADS'
            : tabValue === 2
            ? 'INFLUENCER_ADS'
            : null,
        adType:
          adTypeDropdownText === 'Video'
            ? 'VIDEO'
            : adTypeDropdownText === 'Image'
            ? 'IMAGE'
            : adTypeDropdownText === 'Ad Mob'
            ? 'GOOGLE_MOB'
            : null,
        asc: sort_order,
        startDate:
          startDateRef && startDateRef !== 'MM/DD/YYYY'
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== 'MM/DD/YYYY' ? endDateRef.toDate() : ''
      }
      let path = urls.adsList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)

        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.totalItems,
          totalPages: response?.data?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  const handleEditAds = item => {
    defaultAdsTypeOptions?.map(data => {
      if (data?.value === item?.type) {
        setDefaultAdsType({
          value: data?.value,
          label: data?.label
        })
      }
    })
    setAdsFormData({
      ...item,
      startDate: new Date(item?.startDate),
      endDate: new Date(item?.endDate)
    })
    setAddNewAds(true)
  }

  const handleOnSubmitRoleModel = async () => {
    if (tabValue === 0) {
      try {
        let postJson = {
          title: adsFormData?.title,
          adsType: 'MAIN_ADS',
          type: adsFormData?.type,
          viewsNeeded: adsFormData?.viewsNeeded,
          urlEnabled: adsFormData?.urlEnabled,
          url: adsFormData?.url,
          startDate: adsFormData?.startDate,
          endDate: adsFormData?.endDate,
          id: adsFormData?.id
        }
        let form_data = new FormData()
        form_data.append(
          'adRequestReq',
          new Blob([JSON.stringify(postJson)], {
            type: 'application/json'
          })
        )
        form_data.append('adContentFile', adsContentFile)
        dispatch(uplodateLoading(true))
        let path = urls.addAds
        var res = await ApiCaller.post_formdata(
          form_data,
          path,
          userData?.access_token
        )
        const response = await res.response.json()
        if (response.status == 200) {
          dispatch(uplodateLoading(false))
          showToast(response?.message)
          setAddNewAds(false)
          getAdsList()
        } else {
          dispatch(uplodateLoading(false))
          showDangerToast(response.message)
        }
      } catch (error) {
        dispatch(uplodateLoading(false))
        console.error(error)
        showDangerToast(error.message)
      }
    } else if (tabValue === 1) {
      try {
        let postJson = {
          title: adsFormData?.title,
          adsType: 'SMALL_ADS',
          type: 'IMAGE',
          viewsNeeded: adsFormData?.viewsNeeded,
          urlEnabled: adsFormData?.urlEnabled,
          url: adsFormData?.url,
          startDate: adsFormData?.startDate,
          endDate: adsFormData?.endDate,
          id: adsFormData?.id
        }
        let form_data = new FormData()
        form_data.append(
          'adRequestReq',
          new Blob([JSON.stringify(postJson)], {
            type: 'application/json'
          })
        )
        form_data.append('adContentFile', adsContentFile)
        dispatch(uplodateLoading(true))
        let path = urls.addAds
        var res = await ApiCaller.post_formdata(
          form_data,
          path,
          userData?.access_token
        )
        const response = await res.response.json()
        if (response.status == 200) {
          dispatch(uplodateLoading(false))
          showToast(response?.message)
          setAddNewAds(false)
          getAdsList()
        } else {
          dispatch(uplodateLoading(false))
          showDangerToast(response.message)
        }
      } catch (error) {
        dispatch(uplodateLoading(false))
        console.error(error)
        showDangerToast(error.message)
      }
    }
  }

  const handleAddAds = async () => {
    setAdsFormData({
      id: '',
      title: '',
      type: '',
      viewsNeeded: '',
      urlEnabled: false,
      url: '',
      startDate: new Date(),
      endDate: null
    })
    setAddNewAds(true)
  }

  return (
    <>
      <NavBar />
      <div className='main-content'>
        <NavBarTop />
        <div className='header'>
          <div className='logo'>
            <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
          </div>
        </div>
        <div className='container-fluid mt--7'>
          <div className='row'>
            <div className='col text-left'>
              <div className='card shadow'>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col'>
                      <h3 className='mb-0'>Ad Management</h3>
                    </div>
                    <div className='col text-right'>
                      {userData.admin && tabValue !== 2 ? (
                        <a
                          onClick={handleAddAds}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add Ads
                        </a>
                      ) : edit && tabValue !== 2 ? (
                        <a
                          onClick={handleAddAds}
                          className='btn btn-sm btn-primary text-white'
                        >
                          <AddIcon />
                          Add Ads
                        </a>
                      ) : (
                        <></>
                      )}

                      {/* <a href='#!' className='btn btn-sm btn-primary'>
                      See all
                    </a> */}
                    </div>
                  </div>
                </div>
                <div className='card-header border-0'>
                  <div className='row align-items-center text-left'>
                    <div className='col-md-3'>
                      <InputGroup
                        className='mb-2'
                        style={{ borderRadius: '10px' }}
                      >
                        <Form.Control
                          placeholder='Search by Keyword'
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          aria-label='Amount (to the nearest dollar)'
                        />
                        <InputGroup.Text
                          style={{
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px',
                            borderTopLeftRadius: '0px',
                            borderBottomLeftRadius: '0px'
                          }}
                        >
                          <SearchIcon />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    {tabValue === 0 ? (
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {adTypeDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                setAdTypeDropdownText('Select Type')
                              }
                            >
                              Select Type
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setAdTypeDropdownText('Video')}
                            >
                              Video
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setAdTypeDropdownText('Image')}
                            >
                              Image
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setAdTypeDropdownText('Ad Mob')}
                            >
                              Ad Mob
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className='col-md-1 mb-2 ml-2'>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant='success'
                          style={{
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid #cad1d7',
                            boxShadow: 'none',
                            transform: 'none'
                          }}
                          id='dropdown-basic'
                        >
                          {statusDropdownText}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setStatusDropdownText('Select Status')
                            }
                          >
                            Select Status
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('Active')}
                          >
                            Active
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setStatusDropdownText('In Active')}
                          >
                            In Active
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div
                      className='col-md-4 mb-3'
                      style={{ marginLeft: '40px' }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={['DatePicker', 'DatePicker']}
                        >
                          <DatePicker
                            disableFuture
                            className='custom-date'
                            label='From Date'
                            value={startDate}
                            onChange={newValue => setStartDate(newValue)}
                          />
                          <DatePicker
                            disableFuture
                            label='To Date'
                            value={endDate}
                            onChange={newValue => setEndDate(newValue)}
                            minDate={startDate}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    <div className='col-md-3 mb-2'>
                      <Button
                        variant='contained'
                        className='custom-filter-btn'
                        onClick={handleFilterClick}
                      >
                        Filter
                      </Button>
                      <Button
                        variant='contained'
                        className='ml-2 custom-filter-btn'
                        onClick={handleResetFilter}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                </div>

                <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    indicatorColor='secondary'
                    textColor='inherit'
                    variant='fullWidth'
                    aria-label='full width tabs example'
                  >
                    <Tab label='Main Ads' {...a11yProps(0)} />
                    <Tab label='Small Ads' {...a11yProps(1)} />
                    <Tab label='Influencer' {...a11yProps(2)} />
                  </Tabs>
                </Box>
                {tabValue === 0 ? (
                  <MainAdsTable
                  getAdsList={getAdsList}
                    handleEditAds={handleEditAds}
                    handleDeleteAds={handleDeleteAds}
                    admin={userData.admin}
                    active={active}
                    edit={edit}
                    datalist={datalist}
                  />
                ) : (
                  <></>
                )}
                {tabValue === 1 ? (
                  <SmallAdsTable
                  getAdsList={getAdsList}
                    handleEditAds={handleEditAds}
                    handleDeleteAds={handleDeleteAds}
                    admin={userData.admin}
                    active={active}
                    edit={edit}
                    datalist={datalist}
                  />
                ) : (
                  <></>
                )}
                {tabValue === 2 ? (
                  <InfluencerAdsTable
                  getAdsList={getAdsList}
                    admin={userData.admin}
                    active={active}
                    edit={edit}
                    datalist={[{ id: 1 }]}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddAds
          setAdsContentFile={setAdsContentFile}
          show={addNewAds}
          tabValue={tabValue}
          adsFormData={adsFormData}
          setAdsFormData={setAdsFormData}
          onSubmit={() => handleOnSubmitRoleModel()}
          onHide={() => setAddNewAds(false)}
          defaultAdsType={defaultAdsType}
          defaultAdsTypeOptions={defaultAdsTypeOptions}
        />
      </div>
    </>
  )
}
