import _ from 'lodash'

var ApiCaller = {
  post_with_params: async (jsonObj = {}, params, path = '', token = '') => {
    let query = await ApiCaller.serialize(params)

    const url =
      query != ''
        ? process.env.REACT_APP_API_BASE_URL + path + '?' + query
        : process.env.REACT_APP_API_BASE_URL + path
    // const url = process.env.REACT_APP_API_BASE_URL + path;
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(jsonObj),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? 'Bearer ' + token : ''
        //"isapplysubscription":true
      }
    })

    // if (_.includes([401, 403], res.status)) {
    //   window.location = process.env.PUBLIC_URL + "/login";
    // }
    if (_.includes([401], res.status)) {
      window.location = process.env.PUBLIC_URL + '/login'
    } else if (_.includes([403], res.status)) {
      alert('Logout')
      // return Logout()
    }
    return { response: await res, status: await res.status }
  },

  put_with_params: async (formData = {}, params, path = '', session = {}) => {
    let query = await ApiCaller.serialize(params)

    const url =
      query !== ''
        ? process.env.REACT_APP_API_BASE_URL + path + '?' + query
        : process.env.REACT_APP_API_BASE_URL + path
    const res = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json',
        Authorization: session.token ? 'Bearer ' + session.token : ''
        //"isapplysubscription":true
      }
    })

    if (_.includes([401], res.status)) {
      window.location = process.env.PUBLIC_URL + '/login'
    } else if (_.includes([403], res.status)) {
      alert('Logout')
      //   return Logout()
    }
    return { response: await res, status: await res.status }
  },

  post: async (jsonObj = {}, path = '', token = '') => {
    const url = process.env.REACT_APP_API_BASE_URL + path
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(jsonObj),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? 'Bearer ' + token : ''
        //"isapplysubscription":true
      }
    })

    if (_.includes([401], res.status)) {
      window.location = process.env.PUBLIC_URL + '/login'
    } else if (_.includes([403], res.status)) {
      alert('Logout')
      //   return Logout()
    }
    return { response: await res, status: await res.status }
  },
  get: async (jsonObj = {}, path = '', token = '') => {
    try{
      let query = await ApiCaller.serialize(jsonObj)
      const url =
        query != ''
          ? process.env.REACT_APP_API_BASE_URL + path + '?' + query
          : process.env.REACT_APP_API_BASE_URL + path
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? 'Bearer ' + token : ''
          //"isapplysubscription":true
        }
      })
      if (_.includes([401, 403], res.status)) {
        //  window.location = process.env.PUBLIC_URL + "/login";
      }
      if (_.includes([401], res.status)) {
        //window.location = process.env.PUBLIC_URL + "/login";
      } else if (_.includes([403], res.status)) {
        alert('Logout')
        //   return Logout()
      }
      return { response: await res, status: res.status }
    }catch(error){
      console.log(error)
    }
    
  },
  put: async (jsonObj = {}, path = '', token = '') => {
    const url = process.env.REACT_APP_API_BASE_URL + path
    const res = await fetch(url, {
      method: 'put',
      body: JSON.stringify(jsonObj),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? 'Bearer ' + token : ''
        //"isapplysubscription":true
      }
    })
    if (_.includes([401], res.status)) {
      window.location = process.env.PUBLIC_URL + '/login'
    } else if (_.includes([403], res.status)) {
      alert('Logout')
      //   return Logout()
    }
    return { response: await res, status: await res.status }
  },
  delete: async (jsonObj = {}, path = '', token = '') => {
    const url = process.env.REACT_APP_API_BASE_URL + path
    const res = await fetch(url, {
      method: 'DELETE',
      body: JSON.stringify(jsonObj),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? 'Bearer ' + token : ''
        //"isapplysubscription":true
      }
    })
    if (_.includes([401], res.status)) {
      window.location = process.env.PUBLIC_URL + '/login'
    } else if (_.includes([403], res.status)) {
      alert('Logout')
      //   return Logout()
    }
    return { response: await res, status: await res.status }
  },
  getData: async option => {
    try {
      if (option.params === null) {
        option.params = {}
      }
      if (option.page) {
        option.params.page = option.page
      }

      const fr = await ApiCaller.get(option.params, option.path, option.session)
      const fetchStatus = await fr.status
      const response = await fr.response.json()
      if (fetchStatus === 200) {
        return response
      } else {
        return false
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  serialize: function (obj, prefix) {
    var str = [],
      p
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p]
        str.push(
          v !== null && typeof v === 'object'
            ? this.serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        )
      }
    }
    return str.join('&')
  },
  post_formdata: async (formData = {}, path = '', token) => {
    const url = process.env.REACT_APP_API_BASE_URL + path
    const res = await fetch(url, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: token ? 'Bearer ' + token : ''
      }
    })

    if (_.includes([401, 403], res.status)) {
      // window.location = process.env.PUBLIC_URL + '/login'
      alert("Something went wrong, Please check iamge file and try again.")
    }
    return { response: await res, status: await res.status }
  },
  put_formdata: async (formData = {}, path = '', token) => {
    const url = process.env.REACT_APP_API_BASE_URL + path
    const res = await fetch(url, {
      method: 'PUT',
      body: formData,
      headers: {
        Authorization: token ? 'Bearer ' + token : ''
      }
    })

    if (_.includes([401, 403], res.status)) {
      window.location = process.env.PUBLIC_URL + '/login'
    }
    return { response: await res, status: await res.status }
  },
  put_statuschange: async (formData = {}, path = '', session) => {
    let query = await ApiCaller.serialize(formData)
    const url = process.env.REACT_APP_API_BASE_URL + path + '?' + query
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: session.token ? 'Bearer ' + session.token : ''
      }
    })

    if (_.includes([401, 403], res.status)) {
      window.location = process.env.PUBLIC_URL + '/login'
    }
    return { response: await res, status: await res.status }
  },
  getStatus: status => {
    switch (status) {
      case 1:
        return 'Active'
        break
      case 2:
        return 'Lost'
        break
      case 3:
        return 'Win'
        break
      case 5:
        return 'Disabled'
        break
      default:
        return 'Active'
        break
    }
  }
}
export default ApiCaller
