import { Link, NavLink, useNavigate } from 'react-router-dom'
import { AuthData } from '../../auth/AuthWrapper'
import { useEffect, useReducer, useState } from 'react'
import Logo from '../../assets/img/circo_color_logo2.png'
import useSession from 'react-session-hook'
import $ from 'jquery'
import urls from '../constants/apiurl'
import ApiCaller from '../constants/ApiCaller'
import { showDangerToast, showToast } from '../constants/toaster'
import { useDispatch } from 'react-redux'
import commonSlice, {
  updatePermission,
  updateUserData,
  uplodateLoading
} from '../../redux/features/common/commonSlice'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton } from '@mui/material'
export const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [isValidEmail, setIsValidEmail] = useState(false)
  const session = useSession()
  const navigate = useNavigate()
  const { login } = AuthData()
  const [formData, setFormData] = useReducer(
    (formData, newItem) => {
      return { ...formData, ...newItem }
    },
    { userName: '', password: '' }
  )
  const [errorMessage, setErrorMessage] = useState(null)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/
  useEffect(() => {
    $('body').addClass('bg-default')
  })
  const fetchPermissions = async (id, token) => {
    let path = urls.fetchPermission + '/' + id
    let res = await ApiCaller.get({}, path, token)
    const response = await res.response.json()
    if (response.status == 200) {
      dispatch(updatePermission(response.data))
    }
  }

  const handleChange = event => {
    const inputValue = event.target.value
    setFormData({ userName: inputValue })

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    console.log('emailRegex.test(inputValue)', emailRegex.test(inputValue));
    setIsValidEmail(emailRegex.test(inputValue))

  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const [showPassword, setShowPassword] = useState(false)

  const togglePassword = e => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  const doForgotPassword = async () => {
    if (formData?.userName) {
      navigate('/login')
    } else if (!isValidEmail) {
      showDangerToast('Please enter valid email address')
    } else {
      showDangerToast('Please enter email address')
    }
  }

  return (
    <>
      <div className='main-content'>
        <nav className='navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark'>
          <div className='container px-4'>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              data-target='#navbar-collapse-main'
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <span className='navbar-toggler-icon' />
            </button>
            <div className='collapse navbar-collapse' id='navbar-collapse-main'>
              <div className='navbar-collapse-header d-md-none'>
                <div className='row'>
                  <div className='col-6 collapse-brand'>
                    <a href='../index.html'>
                      <img src='../assets/img/brand/blue.png' />
                    </a>
                  </div>
                  <div className='col-6 collapse-close'>
                    <button
                      type='button'
                      className='navbar-toggler'
                      data-toggle='collapse'
                      data-target='#navbar-collapse-main'
                      aria-controls='sidenav-main'
                      aria-expanded='false'
                      aria-label='Toggle sidenav'
                    >
                      <span />
                      <span />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className='header bg-gradient-primary py-7 py-lg-8'>
          <div className='container'>
            <div className='header-body text-center mb-7'>
              <div className='row justify-content-center'>
                <div className='col-lg-5 col-md-6'>
                  <h1 className='text-white'>Forgot Password!</h1>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='separator separator-bottom separator-skew zindex-100'>
            <svg
              x={0}
              y={0}
              viewBox='0 0 2560 100'
              preserveAspectRatio='none'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
            >
              <polygon
                className='fill-default'
                points='2560 0 2560 100 0 100'
              />
            </svg>
          </div> */}
        </div>
        <div className='container mt--8 pb-5'>
          <div className='row justify-content-center'>
            <div className='col-lg-5 col-md-7'>
              <div className='card bg-secondary shadow border-0'>
                <div className='card-body px-lg-5 py-lg-5'>
                  <div className='text-center text-muted mb-4'>
                    <img src={Logo} width={'50%'} />
                  </div>
                  <form role='form'>
                    <div className='form-group mb-3'>
                      <div className='input-group input-group-alternative'>
                        <div className='input-group-prepend'>
                          <span className='input-group-text'>
                            <i className='ni ni-email-83' />
                          </span>
                        </div>
                        <input
                          className='form-control'
                          placeholder='Email'
                          value={formData.userName}
                          onChange={handleChange}
                          style={{
                            borderColor: isValidEmail ? 'black' : 'red'
                          }}
                          type='email'
                        />
                      </div>
                      {!isValidEmail && (
                        <p className='custom-error-validation'>
                          Please enter a valid email address.
                        </p>
                      )}
                    </div>
                    <div className='text-center'>
                      <button
                        disabled={!isValidEmail}
                        type='button'
                        className='btn btn-primary my-4'
                        onClick={doForgotPassword}
                      >
                        Submit
                      </button>
                    </div>
                    {errorMessage ? (
                      <div className='error'>{errorMessage}</div>
                    ) : null}
                    <div className='text-center'>
                      <NavLink
                        activeClassName='active'
                        style={{ color: '#172b4d !important' }}
                        to={'/login'}
                        className='nav-link'
                      >
                        <small>Back to Login ?</small>
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
