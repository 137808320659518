import * as React from 'react'
import dayjs, { Dayjs } from 'dayjs'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import { useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch } from 'react-redux'

export const NormalUserFilters = () => {
  const [statusDropdownText, setStatusDropdownText] = useState('Select Status')
  const [kycStatusDropdownText, setKycStatusDropdownText] =
    useState('Select Kyc Status')
  const [subscriptionNameDropdownText, setSubscriptionNameDropdownText] =
    useState('Select Subscription')
  const dispatch = useDispatch()
  const [value, setValue] = useState(dayjs())

  return (
    <div className='card-header border-0'>
      <div className='row align-items-center text-left'>
        <div className='col-md-3'>
          <InputGroup className='mb-2' style={{ borderRadius: '10px' }}>
            <Form.Control
              placeholder='Search by Keyword'
              aria-label='Amount (to the nearest dollar)'
            />
            <InputGroup.Text
              style={{
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px'
              }}
            >
              <SearchIcon />
            </InputGroup.Text>
          </InputGroup>
        </div>
        <div className='col-md-1 mb-2 '>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid #cad1d7',
                boxShadow: 'none',
                transform: 'none'
              }}
              id='dropdown-basic'
            >
              {statusDropdownText}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setStatusDropdownText('Select Status')}
              >
                Select Status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setStatusDropdownText('Active')}>
                Active
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setStatusDropdownText('In Active')}>
                In Active
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='col-md-4 mb-3' style={{ marginLeft: '40px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker
                disableFuture
                className='custom-date'
                label='From Date'
                value={value}
                onChange={newValue => setValue(newValue)}
              />
              <DatePicker
                disableFuture
                label='To Date'
                value={value}
                onChange={newValue => setValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div className='col-md-1 mb-2 '>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid #cad1d7',
                boxShadow: 'none',
                transform: 'none'
              }}
              id='dropdown-basic'
            >
              {subscriptionNameDropdownText}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  setSubscriptionNameDropdownText('Select Subscription')
                }
              >
                Select Subscription
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setSubscriptionNameDropdownText('Subscription Name')
                }
              >
                Subscription Name
              </Dropdown.Item>
              {/* <Dropdown.Item
              onClick={() => setStatusDropdownText('In Active')}
            >
              In Active
            </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='col-md-1 mb-2 ' style={{ marginLeft: '80px' }}>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              style={{
                backgroundColor: 'white',
                color: 'black',
                border: '1px solid #cad1d7',
                boxShadow: 'none',
                transform: 'none'
              }}
              id='dropdown-basic'
            >
              {kycStatusDropdownText}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => setKycStatusDropdownText('Select Kyc Status')}
              >
                Select Kyc Status
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setKycStatusDropdownText('Verified')}
              >
                Verified
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => setKycStatusDropdownText('Un-Verify')}
              >
                Un-Verify
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
