import Switch from '@mui/material/Switch'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from '@mui/material/Button'
import dayjs, { Dayjs } from 'dayjs'
import NavBar from '../../structure/NavBar'
import Form from 'react-bootstrap/Form'
import SearchIcon from '@mui/icons-material/Search'
import InputGroup from 'react-bootstrap/InputGroup'
import AddIcon from '@mui/icons-material/Add'
import { NavBarTop } from '../../structure/NavBarTop'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useEffect, useReducer, useState } from 'react'
import Image from '../../custom/Image'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateTopHeader,
  uplodateLoading
} from '../../../redux/features/common/commonSlice'
import { ProfileAbuseTable } from './ProfileAbuseTable'
import { VideoAbuseTable } from './VideoAbuseTable'
import { AbuseReportsList } from './AbuseReportsList'
import urls from '../../constants/apiurl'
import ApiCaller from '../../constants/ApiCaller'
import { showDangerToast } from '../../constants/toaster'
const label = { inputProps: { 'aria-label': 'Size switch demo' } }
export const ReportAbuseList = (props) => {
  const [pagerecord, setPagerecord] = useState({
    totalItems: 0,
    pageNumber: 1,
    pageSize: 10,
    totalPages: 1
  })
  const [sort_type, setSortType] = useState('createdAt')
  const [showAbuseReportDetails, setShowAbuseReportDetails] = useState(false)
  const [statusDropdownText, setStatusDropdownText] = useState('Profile')
  const [value, setValue] = useState(dayjs())
  const [sort_order, setSortOrder] = useState(false)
  const userData = useSelector((state) => state?.userData)
  const [searchText, setSearchText] = useState('')
  const [startDate, setStartDate] = useState('MM/DD/YYYY')
  const [endDate, setEndDate] = useState('MM/DD/YYYY')
  const [datalist, setDatalist] = useState([])
  const [page, setPage] = useState(1)
  const [edit, setEdit] = useState(false)
  const [active, setActive] = useState(false)
  const [deletePermission, setDeletePermission] = useState(false)
  const [view, setView] = useState(false)
  const handleChange = (event, value) => {
    setPage(value)
    setPagerecord({
      totalItems: pagerecord?.totalItems,
      totalPages: pagerecord?.totalPages,
      pageNumber: value,
      pageSize: 10
    })
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateTopHeader('Report & Abuse Manager'))
  })

  useEffect(() => {
    getReportAbuseList(searchText, startDate, endDate)
  }, [sort_order, sort_type, page, props])

  const handleFilterClick = () => {
    getReportAbuseList(searchText, startDate, endDate)
  }

  const handleResetFilter = () => {
    setStartDate('MM/DD/YYYY')
    setEndDate('MM/DD/YYYY')
    setSearchText('')
    getReportAbuseList('')
  }

  const getReportAbuseList = async (
    searchTextRef,
    startDateRef,
    endDateRef,
    pageNo = pagerecord.pageNumber
  ) => {
    try {
      dispatch(uplodateLoading(true))
      let postJson = {
        userName: searchTextRef,
        page: pageNo - 1 || 0,
        limit: pagerecord.pageSize || 10,
        sort: sort_type,
        order: sort_order,
        type: 'PROFILE',
        startDate:
          startDateRef && startDateRef !== 'MM/DD/YYYY'
            ? startDateRef.toDate()
            : '',
        endDate:
          endDateRef && endDateRef !== 'MM/DD/YYYY' ? endDateRef.toDate() : ''
      }
      let path = urls.reportAbuseList
      let res = await ApiCaller.post(postJson, path, userData?.access_token)
      const response = await res.response.json()
      if (response.status == 200) {
        dispatch(uplodateLoading(false))
        setDatalist(response?.data?.response?.items)
        response?.data?.permissionList.forEach((module, moduleIndex) => {
          if (module.permissionName === 'EDIT') {
            setEdit(module.isEnable)
          } else if (module.permissionName === 'ACTIVE') {
            setActive(module.isEnable)
          } else if (module.permissionName === 'DELETE') {
            setDeletePermission(module.isEnable)
          } else if (module.permissionName === 'VIEW') {
            setView(module.isEnable)
          }
        })
        setPagerecord({
          totalItems: response?.data?.response?.totalItems,
          totalPages: response?.data?.response?.totalPages,
          pageNumber: pageNo,
          pageSize: 10
        })
      } else {
        dispatch(uplodateLoading(false))
        showDangerToast(response.message)
      }
    } catch (error) {
      dispatch(uplodateLoading(false))
      console.error(error)
      showDangerToast(error.message)
    }
  }

  return (
    <>
      <>
        <NavBar />
        <div className='main-content'>
          <NavBarTop />
          <div className='header'>
            <div className='logo'>
              <div className='header bg-gradient-primary pb-8 pt-5 pt-md-8'></div>
            </div>
          </div>
          <div className='container-fluid mt--7'>
            <div className='row'>
              <div className='col text-left'>
                <div className='card shadow'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col'>
                        <h3 className='mb-0'>Report & Abuse Manager</h3>
                      </div>
                    </div>
                  </div>
                  <div className='card-header border-0'>
                    <div className='row align-items-center text-left'>
                      <div className='col-md-3'>
                        <InputGroup
                          className='mb-2'
                          style={{ borderRadius: '10px' }}
                        >
                          <Form.Control
                            placeholder='Search by User Name'
                            aria-label='Amount (to the nearest dollar)'
                          />
                          <InputGroup.Text
                            style={{
                              borderTopRightRadius: '10px',
                              borderBottomRightRadius: '10px',
                              borderTopLeftRadius: '0px',
                              borderBottomLeftRadius: '0px'
                            }}
                          >
                            <SearchIcon />
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div
                        className='col-md-4 mb-3'
                        style={{ marginLeft: '20px' }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DatePicker', 'DatePicker']}
                          >
                            <DatePicker
                              disableFuture
                              className='custom-date'
                              label='From Date'
                              value={value}
                              onChange={(newValue) => setValue(newValue)}
                            />
                            <DatePicker
                              disableFuture
                              label='To Date'
                              value={value}
                              onChange={(newValue) => setValue(newValue)}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className='col-md-1 mb-2 '>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant='success'
                            style={{
                              backgroundColor: 'white',
                              color: 'black',
                              border: '1px solid #cad1d7',
                              boxShadow: 'none',
                              transform: 'none'
                            }}
                            id='dropdown-basic'
                          >
                            {statusDropdownText}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Profile')}
                            >
                              Profile
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => setStatusDropdownText('Video')}
                            >
                              Video
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className='col-md-3 mb-2'>
                        <Button
                          variant='contained'
                          className='custom-filter-btn'
                        >
                          Filter
                        </Button>
                        <Button
                          variant='contained'
                          className='ml-2 custom-filter-btn'
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </div>
                  {statusDropdownText === 'Profile' ? (
                    <ProfileAbuseTable
                      setShowAbuseReportDetails={setShowAbuseReportDetails}
                    />
                  ) : (
                    <VideoAbuseTable
                      setShowAbuseReportDetails={setShowAbuseReportDetails}
                    />
                  )}
                  <div className='card-footer py-4'>
                    <nav aria-label='...'>
                      <ul className='pagination justify-content-end mb-0'>
                        <li className='page-item disabled'>
                          <a className='page-link' href='#' tabIndex={-1}>
                            <i className='fas fa-angle-left' />
                            <span className='sr-only'>Previous</span>
                          </a>
                        </li>
                        <li className='page-item active'>
                          <a className='page-link' href='#'>
                            1
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            2 <span className='sr-only'>(current)</span>
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            3
                          </a>
                        </li>
                        <li className='page-item'>
                          <a className='page-link' href='#'>
                            <i className='fas fa-angle-right' />
                            <span className='sr-only'>Next</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <AbuseReportsList
              show={showAbuseReportDetails}
              onHide={() => setShowAbuseReportDetails(false)}
            />
          </div>
        </div>
      </>
    </>
  )
}
