import { useJsApiLoader, Autocomplete } from '@react-google-maps/api'
import { useContext, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { geocodeByLatLng } from 'react-google-places-autocomplete'
import { toast } from 'react-toastify'
const libraries = ['places']

const CommonMap = ({
  placeholder,
  withGMap,
  getAutocompletePlacesLocation
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
    libraries
  })

  const [autocomplete, setAutocompelete] = useState(
    /** @type google.maps.places.Autocomplete */ (null)
  )

  /* @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace()

    if (Object.keys(place).length <= 1) {
      return
    }
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    if (!withGMap) {
      geocodeByLatLng({ lat, lng })
        .then(results => {
          const currentCoordinate = {
            lat,
            lng,
            countryName:
              results[results?.length - 1].address_components[0].long_name,
            countryShortCode:
              results[
                results?.length - 1
              ].address_components[0].short_name?.toLowerCase(),
            address: results[0].formatted_address
          }
          console.log(currentCoordinate)
          toast.success('Location changed sucessfully')
        })
        .catch(error => console.error(error))
    } else {
      getAutocompletePlacesLocation(lat, lng)
    }

    if (!place.geometry) {
      window.alert('Autocomplete returned place contains no geometry')
      return null
    }
  }

  if (!isLoaded) {
    return <div style={{ width: '100%' }}></div>
  }
  return (
    <>
      <Autocomplete
        className='auto_complete'
        onLoad={localAutocomplete => setAutocompelete(localAutocomplete)}
        onPlaceChanged={onPlaceChanged}
      >
        <Form.Control
          type='text'
          style={{ width: '100%' }}
          placeholder={placeholder ?? 'Search location...'}
          ref={originRef}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
            }
          }}
        />
      </Autocomplete>
    </>
  )
}

export default CommonMap
